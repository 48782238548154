import React, { forwardRef } from 'react'
import { Form, Tabs } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Files from './Tabs/Files'
import Details from './Tabs/Details'
import Updates from './Tabs/Updates'
import Recurring from './Tabs/Recurring'
import Generations from './Tabs/Generations'

const { TabPane } = Tabs

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  label {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 1rem;
  }
`

const FormScheduledWorkOrder = forwardRef(({ workOrder, scheduled }, ref) => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const {
    _id,
    active,
    name,
    description,
    executedBy,
    priority,
    specialWorkRequired,
    signatureRequired,
    specialWork,
    rrule,
    tasks
  } = workOrder || {}

  const todayDate = new Date().toISOString().split('.')[0] + 'Z'

  const values = {
    _id,
    active,
    name,
    description,
    executedBy: executedBy && executedBy._id,
    priority,
    rrule:
      rrule ||
      `DTSTART:${todayDate.replace(
        /:|\.|-/gi,
        ''
      )}\nFREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1`,
    signatureRequired,
    specialWorkRequired,
    specialWork: (specialWork || []).map(({ _id }) => _id),
    tasks: (tasks || []).reduce(
      (tasks, { _id, objects, checklist, ...task }) => {
        tasks[_id] = {
          ...task,
          checklist: checklist && checklist._id,
          objects: (objects || []).map(({ _id }) => _id)
        }

        return tasks
      },
      {}
    )
  }

  return (
    <StyledForm
      ref={ref}
      form={form}
      {...formItemLayout}
      initialValues={values}
    >
      <Tabs defaultActiveKey='information'>
        <TabPane
          key='information'
          tab={intl.formatMessage({ id: 'informations' })}
        >
          <Details form={form} workOrder={workOrder} />
        </TabPane>
        {_id && (
          <TabPane key='files' tab={intl.formatMessage({ id: 'files' })}>
            <Files workOrder={workOrder} />
          </TabPane>
        )}
        {scheduled && (
          <TabPane
            forceRender
            key='recurring'
            tab={intl.formatMessage({ id: 'recurring' })}
            className='workorder-tab-recurring'
          >
            <Recurring />
          </TabPane>
        )}
        {scheduled && _id && (
          <TabPane
            key='generations'
            tab={intl.formatMessage({ id: 'generations' })}
          >
            <Generations workOrder={workOrder} />
          </TabPane>
        )}
        {_id && (
          <TabPane key='updates' tab={intl.formatMessage({ id: 'updates' })}>
            <Updates workOrder={workOrder} />
          </TabPane>
        )}
      </Tabs>
    </StyledForm>
  )
})

FormScheduledWorkOrder.propTypes = {
  scheduled: PropTypes.bool,
  workOrder: PropTypes.object
}

export default FormScheduledWorkOrder
