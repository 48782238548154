import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useApi from '../../../hooks/useApi'
import acceptanceService from '../../../services/acceptance'
import { Button } from 'antd'
import Popconfirm from '../../../components/Popconfirm'
import Drawer from '../../../components/Drawer'
import FormAcceptance from './FormAcceptance'

const AcceptanceManagement = ({ acceptance, visible, onSubmit, onClose }) => {
  const form = useRef()

  const [deleting, deleteAcceptance] = useApi(acceptanceService.delete)
  const [saving, saveAcceptance] = useApi(acceptanceService.save)

  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const handleSubmit = async () => {
    try {
      const { ...values } = await form.current.validateFields()
      onSubmit(await saveAcceptance(values))
    } catch (error) {
      console.error(error)
    }
  }

  const loading = saving || deleting

  const handleDelete = async () =>
    onSubmit(await deleteAcceptance(acceptance._id))

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (['admin', 'superadmin'].includes(scope)) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <>
      <Drawer
        title={acceptance && acceptance.name}
        visible={visible}
        onClose={onClose}
        onCancel={onClose}
        footerButtons={drawerButtons}
      >
        <FormAcceptance ref={form} acceptance={acceptance} />
      </Drawer>
    </>
  )
}

AcceptanceManagement.propTypes = {
  acceptance: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

AcceptanceManagement.defaultProps = {
  visible: false,
  isDefect: false
}

export default AcceptanceManagement
