import config from 'config'
import axios from 'helpers/axios'

const scheduledWorkOrdersService = {
  listV2: (params = {}) => axios.get(config.server.host + '/1.1/scheduled-workorder', params),

  create: payload => axios.post('/scheduled-workorder', payload),

  update: (id, payload) => axios.put(`/scheduled-workorder/${id}`, payload),

  delete: id => axios.delete(`/scheduled-workorder/${id}`),

  addFile: (id, payload) => axios.post(`/scheduled-workorder/${id}/file`, payload),

  addComment: (id, payload) => axios.post(`/scheduled-workorder/${id}/comment`, payload)
}

export default scheduledWorkOrdersService
