import { useEffect, useState } from 'react'

import useApi from './useApi'

const defaultGetArgs = value => ({
  name: [value],
  limit: 100
})

const useService = (service, defaultValue, options) => {
  const [loading, get] = useApi(service)
  const [items, setItems] = useState(
    defaultValue
      ? Array.isArray(defaultValue)
        ? defaultValue
        : [defaultValue]
      : []
  )

  const { getArgs, fetchOnMount } = Object.assign(
    {
      fetchOnMount: true,
      getArgs: defaultGetArgs
    },
    options || {}
  )

  const fetch = async (value = '') => {
    const { docs = [] } = await get(getArgs(value))

    if (
      !!defaultValue &&
      typeof defaultValue === 'object' &&
      defaultValue._id
    ) {
      !docs.some(({ _id }) => _id === defaultValue._id) &&
        docs.unshift(defaultValue)
    }

    setItems(docs)
  }

  useEffect(() => {
    ;(async () => {
      fetchOnMount && (await fetch())
    })()
  }, [])

  return { loading, fetch, items }
}

export default useService
