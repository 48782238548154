import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Input, Switch, Form } from 'antd'
import styled from 'styled-components'
import { Icon } from '@ant-design/compatible'

const { Item } = Form
const { TextArea } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const SpecialWorkForm = forwardRef(({ work }, ref) => {
  const intl = useIntl()

  const { name, enabled, title, description } = work || {}

  const values = { name, enabled, title, description }

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Item
        label={intl.formatMessage({ id: 'name' })}
        name='name'
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'name' })} />
      </Item>
      <Item
        label={intl.formatMessage({ id: 'title' })}
        name='title'
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <Input placeholder={intl.formatMessage({ id: 'title' })} />
      </Item>
      <Item
        label={intl.formatMessage({ id: 'description' })}
        name='description'
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'this field is required'
            })
          }
        ]}
      >
        <TextArea
          rows={8}
          placeholder={intl.formatMessage({ id: 'description' })}
        />
      </Item>
      <Item label={intl.formatMessage({ id: 'enabled' })} name='enabled'>
        <Switch
          checkedChildren={<Icon type='check' />}
          unCheckedChildren={<Icon type='close' />}
          defaultChecked={typeof enabled !== 'undefined' ? enabled : true}
        />
      </Item>
    </StyledForm>
  )
})

SpecialWorkForm.propTypes = {
  work: PropTypes.object
}

export default SpecialWorkForm
