import React from 'react'
import moment from 'moment'
import { Table } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

const Generations = ({ workOrder }) => {
  const intl = useIntl()

  const { generations = [] } = workOrder || {}

  const columns = [
    {
      title: intl.formatMessage({ id: 'date' }),
      key: 'date',
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
    },
    {
      title: intl.formatMessage({ id: 'generation' }),
      dataIndex: 'docNumber',
      render: docNumber =>
        intl.formatMessage({ id: 'workorder generated' }) + ` ${docNumber}`
    }
  ]

  return <Table dataSource={generations} columns={columns} />
}

Generations.propTypes = {
  workOrder: PropTypes.object
}

export default Generations
