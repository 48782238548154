export default {
  acceptances: 'Abnahmen',
  activated: 'Aktiviert',
  active: 'Aktiv',
  archive: 'Archivieren',
  'actual status': 'Ist-Situation',
  'add manufacturer': 'Hersteller hinzufügen',
  'add workorder': 'Arbeitsauftrag hinzufügen',
  'add category': 'Kategorie hinzufügen',
  'add checklist': 'Checkliste hinzufügen',
  after: 'nachher',
  approve: 'genehmigen',
  approved: 'freigegeben',
  archived: 'Archiviert',
  before: 'vorher',
  category: 'Kategorie',
  'choose category': 'Kategorie auswählen',
  categories: 'Kategorien',
  cancel: 'Abbrechen',
  'click to upload': 'Klicken zum hochladen',
  comment: 'Kommentar',
  'company address': 'Firmenanschrift',
  'company name': 'Firmenname',
  'sign up': 'Registrieren',
  'company location': 'Ort',
  'confirm deleting': 'Bestätigen Sie das Löschen',
  'confirm overwriting': 'Überschreiben bestätigen',
  'contract section': 'Los',
  'contract sections': 'Lose',
  'edit contractSection': 'Los bearbeiten',
  'add contractSection': 'Los erstellen',
  'already have an account?': 'Haben Sie schon einen Account?',
  'login now': 'Jetzt einloggen',
  'created at': 'Erstellt am',
  'created by': 'Erstellt von',
  'create workorder': 'Auftrag erstellen',
  'create scheduled workorder': 'Geplanten Auftrag erstellen',
  'create field': 'Feld erstellen',
  'create a new scheduled Workorder': 'Neuen geplanten Auftrag erstellen',
  'create task': 'Arbeit erstellen',
  'create a new account': 'Neuen Benutzer erstellen',
  'create account': 'Benutzer erstellen',
  'create manufacturer': 'Hersteller erstellen',
  'create category': 'Kategorie erstellen',
  'task details': 'Task Details',
  'create product': 'Produkt erstellen',
  'Add task': 'Aufgabe hinzufügen',
  'create special work': 'Spezialarbeit erstellen',
  date: 'Datum',
  delete: 'Löschen',
  'deleted successfully': 'Erfolgreich gelöscht',
  'deleting error': 'Löschen fehlgeschlagen',
  description: 'Beschreibung',
  'you can only use letters, numbers and underscore':
    'Sie können nur Buchstaben, Zahlen und Unterstriche verwenden\n',
  'Edit tasks': 'Aufgaben verwalten',
  'Upload files': 'Dokumente hochladen',
  'project name': 'Projektname',
  Remove: 'entfernen',
  depth: 'Tiefe',
  type: 'Typ',
  'project number': 'Projektnummer',
  'add project': 'Projekt hinzufügen',
  'edit project': 'Projekt bearbeiten',
  disabled: 'deaktiviert',
  'select special work': 'Auswahl Spezialarbeiten',
  manager: 'Projektverantwortlich',
  'do you want to archive this project?':
    'Möchten Sie dieses Projekt archivieren?',
  'do you want to delete this acceptance permanently?':
    'Möchten Sie diese Abnahme endgültig löschen?',
  'do you want to delete this recording permanently?':
    'Möchten Sie diese Aufnahme dauerhaft löschen?',
  'do you want to unarchive this project?':
    'Möchten Sie die Archivierung aufheben?',
  'do you really want to remove this product?':
    'Möchten Sie das Produkt wirklich entfernen?',
  sapEnabled: 'SAP Schnittstelle',
  'acceptance form': 'Abnahme Formular',
  done: 'Erledigt',
  'task removing': 'Aufgabe entfernen',
  'task save': 'Das Speichern von Aufgaben schlägt fehl',
  'employee number': 'Mitarbeiter-Nr',
  'edit product': 'Produkt bearbeiten',
  'edit manufacturer': 'Hersteller bearbeiten',
  'edit workorder': 'Arbeitsauftrag bearbeiten',
  'edit request': 'Anfrage bearbeiten',
  'edit recording': 'Aufnahme bearbeiten',
  'edit category': 'Kategorie bearbeiten',
  'edit special work': 'Spezialarbeit bearbeiten',
  'edit field': 'Feld bearbeiten',
  email: 'E-Mail',
  'email and password are required': 'E-Mail und Passwort sind erforderlich',
  'email cannot be empty': 'E-Mail darf nicht leer sein',
  'email is not valid': 'E-Mail ist ungültig',
  'Mark as done': 'Als erledigt markieren',
  'email with further instructions has been sent':
    'E-Mail mit weiteren Anweisungen wurde gesendet',
  enabled: 'Aktiviert',
  error: 'Fehler',
  'file has been imported successfully':
    'Die Datei wurde erfolgreich importiert',
  'first name': 'Vorname',
  'forgot password': 'Passwort vergessen',
  'forgot password?': 'Passwort vergessen?',
  form: 'Formular',
  forms: 'Formulare',
  function: 'Funktion',
  general: 'Allgemein',
  'set a first name': 'Vorname eingeben',
  'set a last name': 'Nachname eingeben',
  'set a password': 'Kennwort eingeben',
  'set an email address': 'E-Mail Adresse eingeben',
  'add user': 'Benutzer hinzufügen',
  'edit user': 'Benutzer bearbeiten',
  'set a function': 'Funktion',
  'set an employee number': 'Mitarbeiter-Nummer',
  'add product': 'Produkt erstellen',
  back: 'zurück',
  'global settings': 'Globale Einstellungen',
  group: 'Gruppe',
  groups: 'Gruppen',
  protocol: 'Protokoll',
  'uploaded by': 'Hochgeladen von',
  'hide archived': 'archivierte ausblenden',
  height: 'Höhe',
  high: 'hoch',
  identification: 'Identifikation',
  'image must smaller than': 'Bild muss kleiner sein als',
  inactive: 'Inaktiv',
  'incorrect email or password': 'Falsche E-Mail oder Passwort',
  informations: 'Informationen',
  invalid: 'ungültig',
  'is required': 'ist erforderlich',
  'in progress': 'in Bearbeitung',
  'in process': 'in Bearbeitung',
  'last login': 'Letzter Login',
  label: 'Label',
  key: 'Key',
  'last name': 'Nachname',
  'updated successfully': 'Erfolgreich aktualisiert',
  'created successfully': 'Erfolgreich gespeichert',
  location: 'Standort',
  'set new password': 'Neues Kennwort setzen',
  locations: 'Standorte',
  'log in to your ddSuite instance': 'Melden Sie sich an Ihrer Instanz an',
  login: 'Anmelden',
  'set password': 'Kennwort setzen',
  'login error': 'Login Error',
  logout: 'Abmelden',
  low: 'niedrig',
  length: 'Länge',
  medium: 'mittel',
  manufacturer: 'Hersteller',
  manufacturers: 'Hersteller',
  'if the user was found in our database, we have sent an email to the provided address.':
    'Falls der Benutzer in unserer Datenbank enthalten ist, haben wir eine E-Mail an die angegebene Adresse verschickt.',
  'email sent': 'E-Mail geschickt',
  modules: 'Module',
  'Mark as undone': 'Als nicht erledigt markieren',
  new: 'Neu',
  no: 'Nein',
  number: 'Nummer',
  'number of': 'Anzahl',
  numeration: 'Nummerierung',
  'object name': 'Benutzerdefinierter Objektname',
  objects: 'Objekte',
  open: 'offen',
  'on hold': 'Warten',
  'set your new password': 'Neues Kennwort setzen',
  complete: 'Abgeschlossen',
  opened: 'offen',
  orders: 'Aufträge',
  'article number': 'Artikelnummer',
  'setup a ddsuite account': 'ddSuite Konto einrichten',
  'this field is required': 'Das ist ein Pflichtfeld',
  incomplete: 'Arbeitsauftrag unvollständig',
  overwrite: 'Überschreiben',
  participant: 'Teilnehmer',
  password: 'Kennwort',
  unarchive: 'Archivierung aufheben',
  'password cannot be empty': 'Passwort kann nicht leer sein',
  'passwords arent identical': 'Passwörter sind nicht identisch',
  phone: 'Telefon',
  photo: 'Foto',
  'photo comment': 'Beschreibung',
  photos: 'Fotos',
  project: 'Projekt',
  projects: 'Projekte',
  products: 'Produkte',
  price: 'Preis',
  quantity: 'Menge',
  'csv export': 'CSV Export',
  'csv import': 'CSV Import',
  'recording form': 'Aufnahme Formular',
  'recording images': 'Bilder',
  recordings: 'Aufnahmen',
  recurring: 'Wiederkehrend',
  reject: 'ablehnen',
  rejected: 'zurückgewiesen',
  requested: 'Angefordert',
  requests: 'Anfragen',
  request: 'Anfrage',
  'reset password': 'Passwort zurücksetzen',
  'retype password': 'Kennwort wiederholen',
  remove: 'Entfernen',
  rights: 'Rechte',
  role: 'Benutzergruppe',
  save: 'Speichern',
  saved: 'Erfolgreich gespeichert',
  'save special work': 'Spezialarbeit speichern',
  'saving error': 'Speichern fehlgeschlagen',
  search: 'Suchen',
  'select a project': 'Projekt auswählen',
  'select form': 'Wählen Formular',
  'select manager': 'Wählen Manager',
  send: 'Senden',
  settings: 'Einstellungen',
  stats: 'Statistiken',
  status: 'Status',
  success: 'Erfolg',
  'supplier number': 'Lieferantennummer',
  'target status': 'Soll-Situation',
  title: 'Titel',
  unarchived: 'nicht archiviert',
  unverified: 'nicht verifiziert',
  'update special work': 'Spezialarbeit aktualisieren',
  'update account': 'Benutzer aktualisieren',
  'update contact': 'Kontakt aktualisieren',
  'uploading error': 'Upload fehlgeschlagen',
  users: 'Benutzer',
  verified: 'Verifiziert',
  'verify selected': 'Selektierte freigeben',
  'workorder created': 'Arbeitsauftrag erfolgreich erstellt',
  'work orders': 'Arbeitsanweisungen',
  width: 'Breite',
  yes: 'Ja',
  'you can only upload PNG file': 'Sie können nur eine PNG-Datei hochladen',
  'your password has been changed': 'Ihr Passwort wurde geändert',
  zip: 'PLZ',
  zipcode: 'PLZ',
  tasks: 'Aufgaben',
  endpoint: 'Endpunkt',
  event: 'Ereignis',
  'recordings name': 'Name der Aufnahmen',
  'head.title.home': 'Dashboard',
  'head.title.recordings': 'Aufnahmen',
  'head.title.acceptances': 'Abnahmen',
  'head.title.products': 'Produkte',
  'head.title.manufacturers': 'Hersteller',
  'head.title.categories': 'Kategorien',
  'head.title.workorders': 'Arbeitsaufträge',
  'head.title.protocol': 'Protokoll',
  'head.title.requests': 'Anfragen',
  'head.title.doclink': 'DOCLink',
  'head.title.objects': 'Objekte',
  'head.title.locations': 'Standorte',
  'head.title.lose': 'Lose',
  'head.title.users': 'Benutzer',
  'head.title.groups': 'Gruppen',
  'head.title.rights': 'Berechtigungen',
  'head.title.modules': 'Module',
  'head.title.objectTypes': 'Objekttypen',
  'head.title.fieldDefinition': 'Felddefinition',
  'head.title.forms': 'Formulare',
  'head.title.settings': 'Einstellungen',
  'head.title.projects': 'Projekte',
  'head.title.contacts': 'Kontakte',
  'head.title.specialwork': 'Spezialarbeiten',
  'head.title.defects': 'Mängel',
  'field definition': 'Felddefinition',
  'due date': 'Zu erledigen am',
  'head.title.tasks': 'Aufgaben',
  'category form': 'Formular',
  'select category form': 'Formular auswählen',
  'open requests': 'Offene Anfragen',
  'open workorders': 'Offene Arbeitsanweisungen',
  priority: 'Priorität',
  none: 'Keine',
  updates: 'Updates',
  'manage tasks': 'Aufgaben verwalten',
  'manage tasks description':
    'Fügen Sie Aufgaben zu diesem Arbeitsauftrag hinzu.',
  'add files': 'Dateien hinzufügen',
  'add files description': 'Fügen Sie Dateien zu diesem Arbeitsauftrag hinzu.',
  'update workorder': 'Arbeitsauftrag aktualisieren',
  workorders: 'Arbeitsaufträge',
  'create a new workorder': 'Neuen Arbeitsauftrag erstellen',
  'select a person': 'Benutzer auswählen',
  assignee: 'Zugewiesen an',
  specialwork: 'Spezialarbeiten',
  'signature required': 'Signatur notwendig',
  categoryMapping: 'Kategorie Mapping',
  'special work required': 'Spezialarbeiten',
  'create user': 'Benutzer erstellen',
  'create contract section': 'Los erstellen',
  'create location': 'Standort erstellen',
  'create object': 'Objekt erstellen',
  'create form': 'Formular erstellen',
  'create project': 'Projekt erstellen',
  'create defect': 'Mangel erstellen',
  'csv product export': 'CSV Produkt Export',
  'update recording': 'Aktualisieren',
  'send to sap': 'An SAP übermitteln',
  uploading: 'Hochladen',
  'uploading to SAP': 'Hochladen zu SAP',
  'reference number': 'Referenz',
  'submitted successfully': 'Erfolgreich übermittelt',
  'update product': 'Produkt aktualisieren',
  'You are about to start sending': 'Hochladen von',
  'to SAP': 'zu SAP',
  'interface-process-description':
    'Der Prozess kann nicht rückgängig gemacht werden und die Aufnahme kann nach dem Upload nicht mehr bearbeitet werden. Möchten Sie jetzt starten?',
  'Uploaded successfully to SAP': 'Erfolgreich zu SAP übertragen',
  'submitted at': 'Übertragen am',
  'scheduled-workorders': 'Auftragsplanung',
  'workorder planning': 'Auftragsplanung',
  'generate pdf': 'PDF generieren',
  view: 'Ansehen',
  edit: 'Bearbeiten',
  'track in mainx': 'In MainX nachverfolgen',
  'confirm password': 'Kennwort bestätigen',
  'recording details': 'Aufnahme ansehen',
  'submitted to sap': 'Erfolgreich zu SAP übermittelt',
  'submitted to mainx': 'Erfolgreich zu MainX übermittelt',
  'your password is not secure': 'Das Kennwort ist nicht sicher',
  'please set a password': 'Bitte setzen Sie ein Kennwort',
  'starts at': 'Startdatum',
  'show all': 'Alle anzeigen',
  'show archived': 'Archivierte anzeigen',
  'show unarchived': 'Nicht archivierte anzeigen',
  task: 'Aufgabe',
  'create mainx workorder': 'Arbeitsauftrag erstellen (MainX)',
  'create mainx task': 'Aufgabe erstellen (MainX)',
  'create mainx tasks': 'Aufgaben erstellen (MainX)',
  'mainx tasks created': 'Aufgaben erstellt (MainX)',
  'email address': 'E-Mail Adresse',
  'mainx workorder created': 'Arbeitsauftrag erstellt (MainX)',
  'assign to workorder': 'Einem Arbeitsauftag hinzufügen',
  defects: 'Mängel',
  'defect creation': 'Mängelerfassung',
  completeInfoBelow:
    'Bitte vervollständigen Sie die untenstehenden Informationen.',
  name: 'Name',
  objectNumber: 'Objektnummer',
  assignedTo: 'Zuweisen an',
  actualSituation: 'Ist-Situation',
  targetSituation: 'Soll-Situation',
  'create defect mainx': 'Mangel erfassen (MainX)',
  'loading...': 'Laden...',
  'create defects mainx': 'Mängel erfassen (MainX)',
  'defect created mainx': 'Mangel erfasst (MainX)',
  defect: 'Mangel',
  skip: 'Überspringen',
  contact: 'Kontakt',
  company: 'Firma',
  contacts: 'Kontakte',
  'create contact': 'Kontakt erstellen',
  'unarchived successfully': 'Archivierung erfolgreich aufgehoben.',
  postcode: 'PLZ',
  country: 'Land',
  street: 'Strasse',
  website: 'Website',
  city: 'Ort',
  estimated_cost: 'Kostenschätzung',
  actions: 'Aktionen',
  'bulk change': 'Mehrfachänderung',
  'reassign the selected defects': 'Neues Zuweisen der ausgewählten Mängel',

  completed: 'abgeschlossen',
  'bulk change button': 'Ändern',
  'change assignment': 'Zuweisung ändern',
  'change status': 'Status ändern',
  protocolReference: 'Protokollreferenz',
  protocolRefExt: 'Protokollreferenz extern',
  protocolExtResponsible: 'Protokoll externer Verantwortlicher',
  protocolResponsible: 'Protokollverantwortlicher',
  images: 'Bilder',
  files: 'Dokumente',
  clickToUpload:
    'Klicken oder ziehen Sie die Datei in diesen Bereich, um sie hochzuladen',
  fileSupportInfo: 'Erlaubte Dateiendungen: PDF, PNG, JPG, JPEG, GIF',
  'images upload': 'Bilder hochladen',
  'add comment': 'Einen Kommentar hinzufügen',
  'edit comment': 'Kommentar bearbeiten',
  'protocol reference': 'Protokollreferenz',
  'protocol reference external': 'Externe Protokollreferenz',
  'external protocol reference': 'Neue externe Protokollreferenz',
  'protocol responsible': 'Protokollverantwortlicher',
  'assigned to': 'Zugewiesen an',
  submit: 'Speichern',
  'upload csv': 'CSV Upload',
  people: 'Personen',
  'link to person': 'Link zur Person',
  'add person': 'Person hinzufügen',
  'new person': 'neue Person',
  person: 'Person',
  'select user': 'Benutzer auswählen',
  'set a name': 'Name eingeben',
  'set a street': 'Strasse eingeben',
  'set a postcode': 'PLZ eingeben',
  'set a city': 'Ort eingeben',
  'set a country': 'Land eingeben',
  'set a website': 'Website eingeben',
  'set email': 'E-Mail Adresse',
  'set phone': 'Telefonnummer eingeben',
  'tasks not assigned': 'Nicht zugewiesene Arbeiten',
  'protocol info': 'Protokollinfos',
  'new password tooltip': 'Das Passwort muss aus mindestens 8 Zeichen bestehen',
  'password compare tooltip': 'Zwei Passwörter sind nicht gleich',
  'select contact': 'Kontakt auswählen',
  'choose a status': 'Status auswählen',
  'choose a form': 'Formular auswählen',
  'choose a group': 'Gruppe auswählen',
  'choose a contract section': 'Los auswählen',
  'choose an objectnumber': 'Objektnummer auswählen',
  'choose a project': 'Projekt auswählen',
  'choose a new contact': 'Neuen Kontakt auswählen',
  'choose a new status': 'Neuen Status auswählen',
  'choose fields': 'Felder auswählen',
  'unassigned persons': 'Nicht zugewiesene Personen',
  'choose a new external protocol protocol reference':
    'Wählen Sie eine neue externe Protokollprotokollreferenz',
  'choose protocol responsible': 'Wählen Sie das verantwortliche Protokoll',
  'field is required': 'Feld ist erforderlich',
  'choose a new protocol reference': 'Wählen Sie eine neue Protokollreferenz',
  'account settings': 'Account Einstellungen',
  language: 'Sprache',
  'sign out': 'Ausloggen',
  'download template': 'Vorlage herunterladen',
  'object types': 'Objekttypen',
  'object type': 'Objekttyp',
  'add objectType': 'Objekttyp erstellen',
  'edit objectType': 'Objekttyp bearbeiten',
  'objectType details': 'Objekttypdetails',
  specification: 'Spezifikation',
  'name already exists': 'Dieser Name existiert bereits.',
  'object already exists': 'Dieses Objekt existiert bereits',
  'field already exists': 'Feld mit diesem Schlüssel existiert bereits',
  'do you want to archive this entry?':
    'Möchten Sie diesen Eintrag archivieren?',
  'create object type': 'Objekttyp erstellen',
  'attachment error': 'Anhangsdatei existiert nicht',
  'will be attached': 'Die Datei wird beim PDF-Export angehängt',
  fields: 'Felder',
  reference: 'Referenz',
  'attached document': 'PDF anfügen',
  'choose a manufacturer': 'Hersteller auswählen',
  'choose a category': 'Kategorie auswählen',
  reset: 'Zurücksetzen',
  'choose document': 'Dokument auswählen',
  'custom fields for tasks':
    'Benutzerdefinierte Felder für Anforderungen und Aufgaben',
  supportEmail: 'Kontakt Instandhalter',
  'supportEmail tooltip':
    'Nach Abschluss des Arbeitsauftrags wird eine Benachrichtigungs-E-Mail an diese Adresse gesendet',
  picture: 'Bild',
  pictures: 'Bilder',
  information: 'Information',
  ean: 'EAN',
  'add task': 'Aufgabe hinzufügen',
  'create unassigned task': 'Nicht zugewiesene Aufgabe erstellen',
  checklists: 'Checklisten',
  'head.title.checklists': 'Checklisten',
  'edit checklist': 'Checkliste bearbeiten',
  'create checklist': 'Checkliste erstellen',
  'form fields': 'Formularfelder',
  'select checklist': 'Checkliste auswählen',
  checklist: 'Checkliste',
  docNumber: 'ID',
  'checklist fields': 'Checklistenfelder',
  'edit status': 'Status bearbeiten',
  'workorder generated': 'Erfolgreich generierter Arbeitsauftrag',
  generations: 'Generierungen',
  generation: 'Generierung',
  finished: 'fertig',
  'show completed': 'Abgeschlossene Aufgaben anzeigen',
  'show current': 'Alle Aufgaben anzeigen',
  'confirm archive': 'Bestätigen Sie das Archivieren',
  'set roles': 'Rollen setzen',
  'please confirm your password': 'Bitte bestätigen Sie Ihr Passwort',
  'standard user': 'Standardbenutzer',
  administrator: 'Administrator',
  superadmin: 'Superadmin',
  roles: 'Rollen',
  'recording archived': 'Verarbeitete Aufnahme archiviert',
  'collapse sidebar': 'Menü einklappen',
  'please confirm the deletion': 'Bitte bestätigen Sie den Löschvorgang',
  'choose a {name}': 'Wähle einen {name}',
  verify: 'Verifizieren',
  'reverse verification': 'Verifizierung aufheben',
  'select which pictures of the recording to attach to object {name}:':
    'Wählen Sie aus, welche Bilder der Aufnahme an das Objekt {name} angehängt werden sollen:',
  'attach pictures to object': 'Bilder an Objekt anhängen',
  'pictures successfully attached to the object':
    'Bilder erfolgreich an das Objekt angehängt',
  'task name': 'Aufgabennname',
  'no object type': 'Kein Objekttyp'
}
