import React from 'react'
import styled from 'styled-components'
import { Button, Input, Space } from 'antd'
import { FormattedMessage } from 'react-intl'
import { SearchOutlined } from '@ant-design/icons'

const Styled = styled('div')`
  padding: 8px;
  button.ant-btn {
    width: 90px;
  }
  .ant-input, .ant-select {
    width: 188px;
    display: block;
    margin-bottom: 8px;
  }
`

const searchColumn = ({ input, intl, value, component }) => {
  const native = !component
  const Component = component || Input

  return {
    filterDropdown: options => {
      const {
        confirm,
        clearFilters,
        selectedKeys,
        setSelectedKeys
      } = options

      return (
        <Styled>
          <Component
            ref={input}
            value={selectedKeys[0]}
            onPressEnter={() => confirm()}
            onChange={event => {
              const value = event && event.target && typeof event.target === 'object'
                ? event.target.value
                : event
              setSelectedKeys(value ? [value] : [])
            }}
            placeholder={intl.formatMessage({ id: 'search' })}
          />
          <Space>
            <Button
              size='small'
              type='primary'
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              disabled={!selectedKeys[0]}
            />
            <Button size='small' onClick={() => clearFilters()}>
              <FormattedMessage id='reset' />
            </Button>
          </Space>
        </Styled>
      )
    },
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered && value ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: async visible => {
      if (visible && native) {
        setTimeout(() => input.current.select())
      }
    },
    defaultFilteredValue: value
  }
}

export default searchColumn
