import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button, notification } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import Popconfirm from '../../../components/Popconfirm'
import FormScheduledWorkOrder from './FormScheduledWorkOrder'
import convertToObjectId from '../../../utils/convertToObjectId'
import scheduledWorkOrders from '../../../services/scheduledWorkOrders'

const WorkOrderManagement = ({
  visible,
  scheduled,
  workOrder,
  onSubmit,
  onClose
}) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createScheduledWorkOrder] = useApi(
    scheduledWorkOrders.create
  )
  const [updating, updateScheduledWorkOrder] = useApi(
    scheduledWorkOrders.update
  )
  const [deleting, deleteScheduledWorkOrder] = useApi(
    scheduledWorkOrders.delete
  )

  const handleSubmit = async () => {
    try {
      const { tasks } = workOrder || {}
      const { _id, ...values } = await form.current.validateFields()

      const preparedTasks = Object.entries(values.tasks || []).map(([key, value]) => {
        const task = tasks.find(({ _id }) => _id === key)
        if (task) {
          const { _id, ...rest } = task
          return Object.assign(
            { _id },
            convertToObjectId({ ...rest, ...value })
          )
        }

        return value
      })

      Object.assign(values, { tasks: preparedTasks })

      onSubmit(
        await (_id
          ? updateScheduledWorkOrder(_id, values)
          : createScheduledWorkOrder(values))
      )
    } catch (e) {
      notification.error({
        message: intl.formatMessage({
          id: 'saving error'
        }),
        description: e.error || e.message
      })
    }
  }

  const handleDelete = async () =>
    onSubmit(await deleteScheduledWorkOrder(workOrder._id))

  const loading = creating || updating || deleting

  const footerDrawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      loading={loading}
      disabled={loading}
      onClick={handleSubmit}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (['admin', 'superadmin'].includes(scope) && !!workOrder) {
    footerDrawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  const { docNumber } = workOrder || {}

  return (
    <>
      <Drawer
        title={
          (docNumber &&
            intl.formatMessage({
              id: 'edit scheduled workorder'
            })) ||
          intl.formatMessage({
            id: 'create a new scheduled Workorder'
          })
        }
        visible={visible}
        onClose={onClose}
        footerButtons={footerDrawerButtons}
      >
        <FormScheduledWorkOrder
          ref={form}
          workOrder={workOrder}
          scheduled={scheduled}
        />
      </Drawer>
    </>
  )
}

WorkOrderManagement.propTypes = {
  scheduled: PropTypes.bool,
  workOrder: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

WorkOrderManagement.defaultProps = {
  visible: false
}

export default WorkOrderManagement
