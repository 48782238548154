import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Modal, notification } from 'antd'

import useApi from '../../../../hooks/useApi'
import FormAttachPictures from './FormAttachPictures'
import recordingService from '../../../../services/recording'

const ModalAttachPicturesToObject = ({
  visible,
  recording,
  onSubmit,
  onClose
}) => {
  const form = useRef()
  const intl = useIntl()
  const [loading, attachImages] = useApi(recordingService.attachImages)

  const handleSubmit = async () => {
    try {
      const { images } = await form.current.validateFields()
      onSubmit(await attachImages(recording._id, { images }))
      notification.success({
        message: intl.formatMessage({
          id: 'pictures successfully attached to the object'
        })
      })
    } catch (e) {
      console.error(e)
    }
    onClose()
  }

  const { name } = (recording || {}).objectId || {}

  return (
    <Modal
      destroyOnClose
      closable={false}
      visible={visible}
      title={intl.formatMessage(
        {
          id:
            'select which pictures of the recording to attach to object {name}:'
        },
        { name }
      )}
      onCancel={onClose}
      okText={intl.formatMessage({ id: 'save' })}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <FormAttachPictures ref={form} recording={recording} />
    </Modal>
  )
}

ModalAttachPicturesToObject.propTypes = {
  visible: PropTypes.bool,
  recording: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ModalAttachPicturesToObject
