import axios from 'helpers/axios'

const fileService = {
  create: payload => axios.post('/file', payload),

  update: (id, payload) => axios.put(`/file/${id}`, payload),

  delete: id => axios.delete(`/file/${id}`)
}

export default fileService
