import React, { useState } from 'react'
import {
  Card,
  Form,
  Input,
  PageHeader,
  Radio,
  Select,
  Switch
} from 'antd'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  CloseOutlined,
  SolutionOutlined
} from '@ant-design/icons'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'

import Tasks from './Tasks'
import useUsers from '../../../../hooks/useUsers'
import useSpecialWorks from '../../../../hooks/useSpecialWorks'

const { Item } = Form
const { Meta } = Card
const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const Styled = styled('div')`
  .details {
    display: ${({ showTasks }) => showTasks ? 'none' : 'block'};
  }
  .tasks {
    display: ${({ showTasks }) => showTasks ? 'block' : 'none'};
  }
  .ant-card {
    margin-top: 16px;
    background: #f2f2f2;
    padding: 1rem;
    cursor: pointer;
    &:hover {
      background: #e8e8e8;
    }
  }
`

const Details = ({ form, workOrder }) => {
  const intl = useIntl()

  const {
    _id,
    createdBy,
    executedBy,
    specialWorkRequired,
    specialWork,
    tasks
  } = workOrder || {}

  const [showTasks, setShowTasks] = useState(false)
  const [visibleSpecialWork, setVisibleSpecialWork] = useState(specialWorkRequired)

  const {
    loading: loadingUsers,
    fetch: fetchUsers,
    items: users
  } = useUsers(executedBy)

  const {
    loading: loadingSpecialWorks,
    fetch: fetchSpecialWorks,
    items: specialWorks
  } = useSpecialWorks(specialWork)

  const handleBack = async () => {
    const pathNames = []
    form.getFieldsError().forEach(({ name }) => {
      if (name.includes('tasks')) {
        pathNames.push(name)
      }
    })
    try {
      await form.validateFields(pathNames)
      setShowTasks(false)
    } catch (e) {}
  }

  return (
    <Styled showTasks={showTasks}>
      <div className='details'>
        <Item name='_id' noStyle>
          <Input hidden />
        </Item>
        <Item
          name='active'
          {...formItemLayout}
          valuePropName='checked'
          label={intl.formatMessage({ id: 'active' })}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Item>
        <Item
          name='name'
          {...formItemLayout}
          label={intl.formatMessage({ id: 'name' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'field is required' })
            }
          ]}
        >
          <Input />
        </Item>
        <Item
          name='description'
          {...formItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <TextArea />
        </Item>
        {(createdBy && createdBy._id) && (
          <Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'created by' })}
          >
            <Select
              disabled
              style={{ width: '100%' }}
              defaultValue={createdBy._id}
            >
              <Option key={createdBy._id} value={createdBy._id}>
                {`${createdBy.fullName} (${createdBy.email})`}
              </Option>
            </Select>
          </Item>
        )}
        <Item
          name='executedBy'
          {...formItemLayout}
          label={intl.formatMessage({ id: 'assignee' })}
        >
          <Select
            showSearch
            allowClear
            filterOption={false}
            loading={loadingUsers}
            style={{ width: '100%' }}
            onSearch={debounce(fetchUsers, 300)}
          >
            {users.map(({ _id, email, fullName }) => (
              <Option key={_id}>{`${fullName} (${email})`}</Option>
            ))}
          </Select>
        </Item>
        <Item
          name='priority'
          {...formItemLayout}
          label={intl.formatMessage({ id: 'priority' })}
        >
          <Radio.Group buttonStyle='solid'>
            <Radio.Button value={0}>
              <FormattedMessage id='none' />
            </Radio.Button>
            <Radio.Button value={1}>
              <ArrowDownOutlined />&nbsp;
              <FormattedMessage id='low' />
            </Radio.Button>
            <Radio.Button value={2}>
              <ArrowDownOutlined />&nbsp;
              <FormattedMessage id='medium' />
            </Radio.Button>
            <Radio.Button value={3}>
              <ArrowUpOutlined />&nbsp;
              <FormattedMessage id='high' />
            </Radio.Button>
          </Radio.Group>
        </Item>
        <Item
          {...formItemLayout}
          valuePropName='checked'
          name='specialWorkRequired'
          label={intl.formatMessage({ id: 'special work required' })}
          getValueFromEvent={value => {
            setVisibleSpecialWork(value)
            return value
          }}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Item>
        {visibleSpecialWork && (
          <Item
            name='specialWork'
            {...formItemLayout}
            label={intl.formatMessage({ id: 'select special work' })}
          >
            <Select
              mode='multiple'
              autoClearSearchValue
              style={{ width: '100%' }}
              loading={loadingSpecialWorks}
              onSearch={debounce(fetchSpecialWorks, 300)}
              placeholder={intl.formatMessage({ id: 'choose special work' })}
            >
              {specialWorks.filter(({ enabled }) => enabled).map(({ _id, name }) => (
                <Option key={_id} value={_id}>{name}</Option>
              ))}
            </Select>
          </Item>
        )}
        <Item
          {...formItemLayout}
          valuePropName='checked'
          name='signatureRequired'
          label={intl.formatMessage({ id: 'signature required' })}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Item>
        {_id && (
          <Item
            name='archived'
            {...formItemLayout}
            valuePropName='checked'
            label={intl.formatMessage({ id: 'archive' })}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Item>
        )}
        <Card onClick={() => setShowTasks(true)}>
          <Meta
            title={intl.formatMessage({ id: 'manage tasks' })}
            avatar={<SolutionOutlined style={{ fontSize: '33px', color: '#196b9e' }} />}
            description={intl.formatMessage({ id: 'manage tasks description' })}
          />
        </Card>
      </div>
      <div className='tasks'>
        <PageHeader
          onBack={handleBack}
          title={intl.formatMessage({ id: 'Edit tasks' })}
        />
        <Tasks items={tasks || []} />
      </div>
    </Styled>
  )
}

Details.propTypes = {
  form: PropTypes.object,
  workOrder: PropTypes.object
}

export default Details
