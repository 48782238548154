import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Tabs, Form } from 'antd'
import styled from 'styled-components'
import Details from './Tabs/Details'
import Photos from './Tabs/Photos'
import { useSelector } from 'react-redux'
import Specification from './Tabs/Specification'

const { TabPane } = Tabs

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const ObjectForm = forwardRef(({ object }, ref) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const { pickedProject } = useSelector(({ project }) => project) || {}

  const {
    _id,
    name,
    isVerified,
    images,
    deleted,
    description,
    project,
    comment,
    location,
    createdBy,
    objectType,
    specificationFields,
    createdAt,
    updatedAt
  } = object || {}

  const fieldsValues = useMemo(() => {
    const { fields } = objectType || []
    const specFields = {}

    ;(fields || []).map(({ _id, label, key, element, options }) => {
      const field = (specificationFields || []).find(
        extraField => extraField.key === key
      )
      specFields[_id] = {
        _id,
        label,
        key,
        element,
        options,
        choices: (field && field.choices) || [],
        value: (field && field.value) || ''
      }
      return fields
    })
    return specFields
  }, [objectType, specificationFields])

  const values = {
    _id,
    name,
    isVerified,
    images: (images || []).reduce((images, { _id, description, comment }) => {
      images[_id] = { comment: description || comment }
      return images
    }, {}),
    deleted,
    project: object ? project && project._id : pickedProject._id,
    description,
    comment,
    createdBy,
    specificationFields,
    objectType: object ? objectType && objectType._id : '',
    location: object ? location && location._id : '',
    fields: fieldsValues || [],
    createdAt,
    updatedAt
  }

  return (
    <StyledForm ref={ref} form={form} {...formLayout} initialValues={values}>
      <Tabs defaultActiveKey='information'>
        <TabPane
          key='information'
          tab={intl.formatMessage({ id: 'informations' })}
        >
          <Details />
        </TabPane>
        {images && (
          <TabPane
            key='photos'
            tab={intl.formatMessage({ id: 'photos' })}
            forceRender
          >
            <Photos object={object} />
          </TabPane>
        )}
        {!!objectType && objectType.fields && objectType.fields.length > 0 && (
          <TabPane
            tab={intl.formatMessage({ id: 'specification' })}
            key='extraFields'
            forceRender
          >
            <Specification object={object} />
          </TabPane>
        )}
      </Tabs>
    </StyledForm>
  )
})

ObjectForm.propTypes = {
  object: PropTypes.object
}

export default ObjectForm
