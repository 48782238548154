import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, Button } from 'antd'
import { Icon } from '@ant-design/compatible'
import specialWorkService from 'services/specialWork'
import PageTitle from 'components/Global/PageTitle'
import useApi from '../../../hooks/useApi'
import usePagination from '../../../hooks/usePagination'
import searchColumn from '../../../utils/searchColumn'
import SpecialWorkManagement from './SpecialWorkManagement'

const SpecialWorkPage = () => {
  const intl = useIntl()
  const input = useRef()
  const [work, setWork] = useState()
  const [data, setData] = useState({})
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, fetchSpecialWork] = useApi(specialWorkService.listV2)

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })
  const loadData = async () => {
    const response = await fetchSpecialWork({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = work => {
    setWork(work)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setWork()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const filtersEnabled = [
    {
      text: intl.formatMessage({ id: 'active' }),
      value: true
    },
    {
      text: intl.formatMessage({ id: 'inactive' }),
      value: false
    }
  ]

  const { filterOptions: { enabled: createdByFilters = [] } = {} } = data

  const columns = [
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input, intl, value: filters.name })
    },
    {
      title: intl.formatMessage({ id: 'active' }),
      dataIndex: 'enabled',
      key: 'enabled',
      render: active =>
        active && (
          <Icon
            type='check'
            style={{ color: '#005591', display: 'flex', alignSelf: 'center' }}
          />
        ),
      filters: filtersEnabled,
      filteredValue: filters.enabled,
      defaultSortOrder: sortField === 'enabled' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: true,
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      filters: createdByFilters.map(user => {
        const { firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: user._id
        }
      }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    },
    {
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={() => handleOpen(record)}>
            <Icon type='edit' />
          </Button>
        </div>
      )
    }
  ]

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.specialwork'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'specialwork' })}
        buttons={[
          <Button
            type='primary'
            key='create_special_work'
            onClick={() => handleOpen()}
          >
            <FormattedMessage id='create special work' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
        expandedRowRender={record => (
          <div>
            <p style={{ fontWeight: 'bold' }}>{record.title}</p>
            <p>{record.description}</p>
          </div>
        )}
      />

      <SpecialWorkManagement
        visible={drawerVisible}
        work={work}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  )
}

export default SpecialWorkPage
