import React from 'react'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import useObjects from '../hooks/useObjects'

const SearchObjectNumber = props => {
  const {
    items,
    fetch,
    loading
  } = useObjects()

  const options = items.map(({ _id, name }) => ({
    value: _id,
    label: name
  }))

  return (
    <Select
      {...props}
      allowClear
      showSearch
      showArrow={false}
      loading={loading}
      options={options}
      filterOption={false}
      notFoundContent={null}
      defaultActiveFirstOption={false}
      onSearch={debounce(fetch, 300)}
    />
  )
}

export default SearchObjectNumber
