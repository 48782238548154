import React, { forwardRef, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Select, Checkbox, Spin } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { PageContext } from '../../../providers/PageProvider'
import useLocations from '../../../hooks/useLocations'
import debounce from 'lodash/debounce'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  label {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 1rem;
  }
`

const Loader = () => (
  <div className='objects-spinner-container'>
    <Spin size='small' />
  </div>
)

const FormAcceptance = forwardRef(({ acceptance }, ref) => {
  const intl = useIntl()
  const { activeAcceptance, setActiveAcceptance } = useContext(PageContext)

  const { pickedProject, projectList = [] } =
    useSelector(({ project }) => project) || {}

  const { _id, name, project, participant, location, archived } =
    acceptance || {}

  const {
    loading: loadingLocations,
    fetch: fetchLocations,
    items: locations
  } = useLocations(location)

  const values = {
    _id,
    name,
    project: (project && project._id) || pickedProject._id,
    participant,
    location: (location && location._id) || '',
    archived: archived || false
  }

  const onSelectOption = value => {
    const updatedAcceptance = { ...activeAcceptance }
    updatedAcceptance.location = locations.find(item => item._id === value)
    setActiveAcceptance(updatedAcceptance)
  }

  const renderProjects = useMemo(() => {
    return (
      <Select
        showSearch
        allowClear
        style={{ width: '100%' }}
        optionFilterProp='children'
      >
        {projectList.map(project => (
          <Option
            data={project}
            key={project.projectName + project._id}
            value={project._id}
          >
            {project.projectName}
          </Option>
        ))}
      </Select>
    )
  }, [projectList])

  const renderLocations = useMemo(
    () => (
      <Select
        showSearch
        allowClear
        style={{ width: '100%' }}
        optionFilterProp='children'
        onSearch={debounce(fetchLocations, 300)}
        notFoundContent={loadingLocations && <Loader />}
        onSelect={value => onSelectOption(value)}
      >
        {locations.map((item, i) => (
          <Option key={i + item._id} data={item} value={item._id}>
            {item.name}
          </Option>
        ))}
      </Select>
    ),
    [locations, loadingLocations]
  )

  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Item name='_id' noStyle>
        <Input hidden />
      </Item>
      <Item
        {...formItemLayout}
        name='name'
        label={intl.formatMessage({ id: 'name' })}
      >
        <Input />
      </Item>
      <Item
        {...formItemLayout}
        label={intl.formatMessage({ id: 'project' })}
        name='project'
      >
        {renderProjects}
      </Item>
      <Item
        {...formItemLayout}
        label={intl.formatMessage({ id: 'participant' })}
        name='participant'
      >
        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
      </Item>
      <Item
        {...formItemLayout}
        label={intl.formatMessage({ id: 'location' })}
        name='location'
      >
        {renderLocations}
      </Item>
      <Item
        {...formItemLayout}
        name='archived'
        label={intl.formatMessage({ id: 'archived' })}
        valuePropName='checked'
      >
        <Checkbox defaultChecked={archived} />
      </Item>
    </StyledForm>
  )
})

FormAcceptance.propTypes = {
  acceptance: PropTypes.object
}

export default FormAcceptance
