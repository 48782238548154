import React, { useContext, useEffect, useMemo, useState } from 'react'
import locationService from '../../../../services/location'
import useApi from '../../../../hooks/useApi'
import { Form, Input, Select, Spin, Switch } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import objectTypesService from '../../../../services/objectTypes'
import { PageContext } from '../../../../providers/PageProvider'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

const Loader = () => (
  <div className='objects-spinner-container'>
    <Spin size='small' />
  </div>
)

const Details = () => {
  const intl = useIntl()
  const { object, setObject } = useContext(PageContext)
  const [locations, setLocations] = useState([])
  const [objectTypes, setObjectTypes] = useState([])
  const [loadingLocations, fetchLocations] = useApi(locationService.listV2)
  const [loadingObjectTypes, fetchObjectTypes] = useApi(
    objectTypesService.listV2
  )

  const { settings } = useSelector(({ settings }) => ({ settings }))
  const { pickedProject, projectList = [] } =
    useSelector(({ project }) => project) || {}

  const loadLocations = async value => {
    const params = {}

    if (value) {
      params.name = [value]
      params.limit = 100
    }

    const response = await fetchLocations(params)
    setLocations(response.docs || [])
  }

  const loadObjectTypes = async value => {
    const params = {}

    if (value) {
      params.name = [value]
      params.limit = 100
    }

    const response = await fetchObjectTypes(params)
    setObjectTypes(response.docs || [])
  }

  const selectOption = (value, type) => {
    const updatedObject = { ...object }

    if (type === 'project') {
      updatedObject.project = projectList.find(project => project._id === value)
    } else if (type === 'locations') {
      updatedObject.location = locations.find(project => project._id === value)
    } else {
      updatedObject.objectType = objectTypes.find(
        project => project._id === value
      )
    }

    setObject(updatedObject)
  }

  useEffect(() => {
    !loadingLocations && loadLocations()
  }, [])

  useEffect(() => {
    !loadingObjectTypes && loadObjectTypes()
  }, [])

  const selectProjects = useMemo(() => {
    return (
      <Select
        showSearch
        allowClear
        style={{ width: '100%' }}
        optionFilterProp='children'
        placeholder={intl.formatMessage({ id: 'choose a project' })}
        disabled={pickedProject._id && !object}
        filterOption={(input, { children }) =>
          children.toLowerCase().includes(input.toLowerCase())
        }
        onSelect={value => selectOption(value, 'project')}
      >
        {projectList.map(({ _id, projectName }) => (
          <Option key={_id} value={_id}>
            {projectName}
          </Option>
        ))}
      </Select>
    )
  }, [projectList])

  const selectLocations = useMemo(() => {
    return (
      <Select
        showSearch
        allowClear
        style={{ width: '100%' }}
        optionFilterProp='children'
        notFoundContent={loadingLocations && <Loader />}
        onSearch={value => loadLocations(value)}
        onSelect={value => selectOption(value, 'locations')}
      >
        {(locations || []).map(location => (
          <Option key={location._id} value={location._id}>
            {location.name}
          </Option>
        ))}
      </Select>
    )
  }, [locations])

  const selectObjectTypes = useMemo(() => {
    return (
      <Select
        showSearch
        style={{ width: '100%' }}
        notFoundContent={loadingObjectTypes && <Loader />}
        placeholder={intl.formatMessage({ id: 'select form' })}
        optionFilterProp='children'
        onSearch={value => loadObjectTypes(value)}
        onSelect={value => selectOption(value, 'objectType')}
      >
        {(objectTypes || []).map(objectType => {
          return (
            <Option
              key={objectType._id}
              data={objectType}
              value={objectType._id}
            >
              {objectType.name}
            </Option>
          )
        })}
      </Select>
    )
  }, [objectTypes])

  return (
    <>
      <Item name='_id' noStyle>
        <Input type='hidden' />
      </Item>
      <Item
        name='name'
        label={
          <span>
            {settings &&
              settings.data &&
              settings.data.buildx &&
              settings.data.buildx.objectName}
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        rules={[{ required: false }]}
      >
        <Input disabled={!!(object && object._id)} />
      </Item>
      <Item
        name='project'
        {...formItemLayout}
        label={intl.formatMessage({ id: 'project' })}
      >
        {selectProjects}
      </Item>
      <Item
        name='location'
        {...formItemLayout}
        label={intl.formatMessage({ id: 'location' })}
      >
        {selectLocations}
      </Item>
      <Item
        name='description'
        {...formItemLayout}
        label={intl.formatMessage({ id: 'description' })}
      >
        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
      </Item>
      <Item
        name='comment'
        {...formItemLayout}
        label={intl.formatMessage({ id: 'comment' })}
      >
        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
      </Item>
      <Item
        name='isVerified'
        {...formItemLayout}
        label={intl.formatMessage({
          id: 'verified'
        })}
      >
        <Switch defaultChecked={!!(object && object.isVerified)} />
      </Item>
      <Item
        name='objectType'
        {...formItemLayout}
        rules={[{ required: false }]}
        label={intl.formatMessage({ id: 'object type' })}
      >
        {selectObjectTypes}
      </Item>
    </>
  )
}

export default Details
