import moment from 'moment'

import config from 'config'
import axios from 'helpers/axios'

const AcceptanceService = {
  async list (params = {}) {
    return axios.get('/acceptance', params)
  },

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/acceptance', params)
  },

  async save (acceptance) {
    const params = {
      name:
        acceptance.name ||
        `Abnahme vom ${moment(acceptance.createdAt).format(
          'DD.MM.YYYY HH:ss'
        )}`,
      project: acceptance.project || null,
      participant: acceptance.participant,
      archived: acceptance.archived,
      location: acceptance.location
    }

    if (acceptance.locationName) {
      params.location = acceptance.locationName
    }

    return axios.put(`/acceptance/${acceptance._id}`, params)
  },

  async delete (acceptance) {
    return axios.delete(`/acceptance/${acceptance._id}`)
  },

  downloadPDF: id =>
    axios.get(
      `/acceptance/${id}/pdf`,
      {},
      {
        responseType: 'arraybuffer'
      }
    ),

  exportCSV: id =>
    axios.get(
      `acceptance-csv/${id}`,
      {},
      {
        responseType: 'arraybuffer'
      }
    )
}

export default AcceptanceService
