import config from 'config'
import axios from 'helpers/axios'

const objectService = {
  objectsList: (params = {}) => axios.get('/object', params),

  list: (params = {}) => axios.get('/object', params),

  listV2: (params = {}, incomeProjectId) => {
    if (incomeProjectId) {
      params.project = incomeProjectId
    }
    return axios.get(config.server.host + '/1.1/object', params)
  },

  create: (params = {}) => axios.post('/object', params),

  update: (id, data) => axios.put(`/object/${id}`, data),

  downloadPDF: id =>
    axios.get(
      `/object/${id}/pdf`,
      {},
      {
        responseType: 'arraybuffer'
      }
    ),

  save: object => {
    const params = {
      comment: object.comment,
      description: object.description,
      disabled: object.disabled,
      project: object.project ? object.project : {}
    }

    if (object.location) {
      params.location = object.location._id
        ? object.location._id
        : object.location
    }

    if (object.isVerified !== undefined) {
      params.isVerified = !!object.isVerified
    }

    if (
      typeof params.project === 'object' &&
      Object.keys(params.project).length === 0
    ) {
      delete params.project
    }

    if (object.specificationFields && object.specificationFields.length) {
      params.specificationFields = object.specificationFields
    }

    if (object.objectType) {
      params.objectType = object.objectType._id
        ? object.objectType._id
        : object.objectType
    }

    if (object._id) {
      return axios.put(`/object/${object._id}`, params)
    } else {
      params.name = object.name
      return axios.post('/object', params)
    }
  },

  async searchObjects (limit, offset, name) {
    const token = localStorage.getItem('authToken')
    const response = await axios.get(
      `${config.server.host}/1.1/object?limit=${limit}&offset=${offset}&name[]=${name}`,
      {
        headers: {
          authorization: token
        }
      }
    )
    return response.data
  },

  async uploadCSV (file, force = false) {
    const data = new FormData()
    data.append('file', file)
    return axios.post(`/object/importcsv${force ? '?force=true' : ''}`, data)
  },

  exportCSV: (params = {}) =>
    axios.get('/object/exportCSV', params, {
      responseType: 'arraybuffer'
    })
}

export default objectService
