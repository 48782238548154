import React, { useContext, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Drawer from '../../../components/Drawer'
import { Button } from 'antd'
import { PageContext } from '../../../providers/PageProvider'
import RecordingPreview from './RecordingPreview'
import DefectForm from './DefectForm'
import ObjectManagement from '../../SettingsPages/Objects/ObjectManagment'
import useApi from '../../../hooks/useApi'
import recordingService from '../../../services/recording'

const DefectCreationManagement = ({ defect, visible, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const { setDefectCreationData } = useContext(PageContext)
  const [saving, createDefect] = useApi(recordingService.createDefect)
  const [drawerObjectVisible, setDrawerObjectVisible] = useState(false)

  const updateStateDefect = (key, value) => {
    setDefectCreationData({
      ...defect,
      [key]: value
    })
  }

  const handleNextRecord = async () => {
    try {
      const incomeData = { ...defect }
      const { ...values } = await form.current.validateFields()
      const {
        income,
        step: { index, total }
      } = defect || {}

      if (index + 1 < total) {
        const outputData = { ...incomeData.list[index + 1] }

        outputData.protocolRefExt =
          values.protocolRefExt || income.protocolRefExt
        outputData.protocolResponsible =
          values.protocolResponsible || income.protocolResponsible
        outputData.assignedTo = values.assignedTo || income.assignedTo
        outputData.contractSection =
          values.contractSection || income.contractSection

        const actualSituation =
          (outputData &&
            outputData.images &&
            outputData.images.map(image => image.comment).join('; ')) ||
          null

        setDefectCreationData({
          ...incomeData,
          income: { ...outputData, actualSituation },
          step: { ...incomeData.step, index: index + 1 }
        })
      } else {
        onClose()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmitObject = objectRecord => {
    setDefectCreationData({
      ...defect,
      income: { ...defect.income, objectId: objectRecord }
    })
    handleCloseObject()
  }

  const handleSaveDefect = async () => {
    try {
      const { _id, ...values } = await form.current.validateFields()

      const { list = [], step = {} } = defect || {}

      const recordingId = (list[step.index] && list[step.index]._id) || _id

      if (recordingId) {
        await createDefect(recordingId, values)
        if (defect.type !== 'acceptance') {
          onSubmit()
        } else {
          await handleNextRecord()
        }
      } else {
        onSubmit()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleObjectOpen = () => {
    setDrawerObjectVisible(true)
  }

  const handleCloseObject = () => {
    setDrawerObjectVisible(false)
  }

  const drawerButtons = useMemo(
    () => (
      <>
        <Button onClick={onClose}>
          <FormattedMessage id='cancel' />
        </Button>
        {defect.type === 'acceptance' && (
          <Button onClick={handleNextRecord}>
            <FormattedMessage id='skip' />
          </Button>
        )}

        <Button
          key='ok'
          type='primary'
          onClick={handleSaveDefect}
          loading={saving}
          disabled={saving}
        >
          <FormattedMessage id='create defect' />
        </Button>
      </>
    ),
    [saving, defect]
  )

  const { income = {}, step = {} } = defect || {}

  return (
    <>
      <Drawer
        title={`
          ${intl.formatMessage({ id: 'defect creation' })}
          ${
            typeof step.index !== 'undefined'
              ? step.index + 1 + '/' + step.total
              : ''
          }
        `}
        visible={visible}
        onClose={onClose}
        footerButtons={drawerButtons}
      >
        <p>{intl.formatMessage({ id: 'completeInfoBelow' })}</p>
        {income && income.images && income.images.length > 0 && (
          <RecordingPreview recording={income} />
        )}

        <DefectForm
          ref={form}
          data={income || {}}
          handleObjectOpen={handleObjectOpen}
          updateStateDefect={updateStateDefect}
        />

        <ObjectManagement
          object={{}}
          visible={drawerObjectVisible}
          onSubmit={handleSubmitObject}
          onClose={handleCloseObject}
        />
      </Drawer>
    </>
  )
}

DefectCreationManagement.propTypes = {
  defect: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

DefectCreationManagement.defaultProps = {
  defect: {},
  visible: false
}

export default DefectCreationManagement
