import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import PageTitle from 'components/Global/PageTitle'
import saveAs from 'file-saver'
import usePagination from '../../../hooks/usePagination'
import useApi from '../../../hooks/useApi'
import acceptanceService from '../../../services/acceptance'
import moment from 'moment'
import { Dropdown, Menu, Table, Tag } from 'antd'
import { Icon } from '@ant-design/compatible'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import searchColumn from '../../../utils/searchColumn'
import { useSelector } from 'react-redux'
import recordingService from '../../../services/recording'
import AcceptanceManagement from './AcceptanceManagement'
import PageProvider from '../../../providers/PageProvider'
import DefectCreationManagement from './DefectCreationManagement'
import RecordingManagement from '../Recordings/RecordingManagement'

const { Item, Divider } = Menu

const AcceptancesPage = () => {
  const intl = useIntl()
  const inputName = useRef()

  const [recording, setRecording] = useState()
  const [acceptance, setAcceptance] = useState()
  const [data, setData] = useState({})
  const [defectCreationData, setDefectCreationData] = useState()
  const [loading, fetchAcceptance] = useApi(acceptanceService.listV2)
  const [recordingDrawer, setRecordingDrawer] = useState(false)
  const [defectCreationVisible, setDefectCreationVisible] = useState(false)
  const [acceptanceDrawerVisible, setAcceptanceDrawerVisible] = useState(false)

  const pickedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )

  const objectName = useSelector(
    ({
      settings: {
        data: { buildx: { objectName = 'object number' } = {} }
      }
    }) => objectName
  )

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt',
    filters: {}
  })

  const loadData = async () => {
    const response = await fetchAcceptance({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = record => {
    setAcceptance(record)
    setAcceptanceDrawerVisible(true)
  }

  const handleClose = () => {
    setDefectCreationVisible(false)
    setAcceptanceDrawerVisible(false)
    setRecordingDrawer(false)
    setAcceptance()
    setDefectCreationData()
    setRecording()
    return loadData()
  }

  const getPDF = async ({ _id, docNumber }, type) => {
    const service = type === 'acceptance' ? acceptanceService : recordingService

    saveAs(
      new Blob([await service.downloadPDF(_id)], {
        type: 'application/pdf'
      }),
      `${docNumber}.pdf`
    )
  }

  const handleExportCSV = async ({ _id, name }) => {
    saveAs(
      new Blob([await acceptanceService.exportCSV(_id)], {
        type: 'text/csv'
      }),
      `${name}.csv`
    )
  }

  const createMultipleDefects = data => {
    const { recordings } = data || []

    const recordingsList = recordings.filter(
      item =>
        !item.submissions || item.submissions.every(e => e.name !== 'defect')
    )

    const step = {
      index: 0,
      total: recordingsList.length
    }

    const actualSituation =
      (recordingsList[0] &&
        recordingsList[0].images &&
        recordingsList[0].images.map(image => image.comment).join('; ')) ||
      null

    setDefectCreationData({
      income: {
        ...recordingsList[0],
        actualSituation
      },
      step,
      type: 'acceptance',
      list: recordingsList
    })
    setDefectCreationVisible(true)
  }

  const createSingleDefect = data => {
    setDefectCreationData({ income: data })
    setDefectCreationVisible(true)
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const { filterOptions: { createdBy: createdByFilters = [] } = {} } = data

  const columns = useMemo(() => {
    const cols = [
      {
        title: intl.formatMessage({ id: 'number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...searchColumn({
          input: inputName,
          intl,
          value: filters.docNumber
        })
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        ...searchColumn({
          input: inputName,
          intl,
          value: filters.name
        })
      },
      {
        title: intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder: sortField === 'createdAt' && sortOrder
      },
      {
        title: intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => {
          const { firstName = '', lastName = '' } = createdBy || {}
          return `${firstName} ${lastName}`
        },
        filters:
          createdByFilters.length > 0 &&
          createdByFilters.map(user => {
            const { _id, firstName = '', lastName = '' } = user
            return {
              text: `${firstName} ${lastName}`,
              value: _id
            }
          }),
        defaultFilteredValue: filters.createdBy
      }
    ]

    if (!pickedProject._id) {
      cols.push({
        title: intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    cols.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Item key='0' onClick={() => handleOpen(record)}>
                  <Icon type='edit' />
                  &nbsp;
                  <FormattedMessage id='edit' />
                </Item>
                <Item key='1' onClick={() => getPDF(record, 'acceptance')}>
                  <Icon type='file-pdf' />
                  &nbsp;
                  <FormattedMessage id='generate pdf' />
                </Item>

                <Item key='1' onClick={() => handleExportCSV(record)}>
                  <Icon type='file' />
                  &nbsp;
                  <FormattedMessage id='csv export' />
                </Item>

                <Divider />
                {record.recordings &&
                  record.recordings.filter(
                    item =>
                      !item.submissions ||
                      !item.submissions.some(e => e.name === 'defect')
                  ).length > 0 && (
                    <Item
                      key='5'
                      disabled={record.recordings.length === 0}
                      onClick={() => createMultipleDefects(record)}
                    >
                      <Icon type='plus' />
                      &nbsp;
                      <FormattedMessage id='create defects mainx' />
                    </Item>
                  )}

                {record.recordings &&
                  record.recordings.length > 0 &&
                  record.recordings.filter(
                    item =>
                      !item.submissions ||
                      !item.submissions.some(e => e.name === 'defect')
                  ).length === 0 && (
                    <Item disabled key='5'>
                      <CheckOutlined />
                      &nbsp;
                      <FormattedMessage id='defect created' />
                    </Item>
                  )}
              </Menu>
            }
            trigger={['click']}
          >
            <a className='ant-dropdown-link' href='#'>
              <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown>
        </div>
      )
    })
    return cols
  }, [sortOrder, sortField, filters, inputName, createdByFilters])

  const expandedRowRender = acceptance => {
    acceptance.recordings.sort((a, b) => {
      const unixMilliSecondsA = moment(a.createdAt).valueOf()
      const unixMilliSecondsB = moment(b.createdAt).valueOf()
      if (unixMilliSecondsA < unixMilliSecondsB) {
        return -1
      }
      if (unixMilliSecondsA > unixMilliSecondsB) {
        return 1
      }
      return 0
    })

    const expandedRows = [
      {
        title: 'Number',
        key: 'docNumber',
        dataIndex: 'docNumber',
        render: docNumber => docNumber || ''
      },
      {
        title: objectName,
        key: 'object',
        dataIndex: 'objectId',
        render: object => (object && object.name) || ''
      },
      {
        title: intl.formatMessage({ id: 'reference number' }),
        key: 'submittedRefNr',
        dataIndex: 'submittedRefNr',
        render: (text, record) => {
          const source = []

          if (record.submittedWorkorder && record.submittedWorkorderId) {
            source.push(
              <span key={0}>
                <Tag color='blue'>
                  <CheckOutlined /> Workorder (
                  {record.submittedWorkorderId.docNumber})
                </Tag>
                &nbsp;&nbsp;
              </span>
            )
          }

          if (record.submittedTask && record.submittedTaskId) {
            source.push(
              <span key={1}>
                <Tag color='blue'>
                  <CheckOutlined /> Task ({record.submittedTaskId.docNumber})
                </Tag>
                &nbsp;&nbsp;
              </span>
            )
          }

          if (record.submitted) {
            source.push(
              <span key={3}>
                <Tag color='blue'>
                  <CheckOutlined /> SAP ({record.submittedRefNr})
                </Tag>
              </span>
            )
          }

          if (
            record.submissions &&
            record.submissions.some(e => e.name === 'defect')
          ) {
            source.push(
              <span key={4}>
                <Tag color='blue'>
                  <CheckOutlined />
                  &nbsp;
                  <FormattedMessage id='defect' />
                  &nbsp; (
                  {record.submissions.map((a, index) => (
                    <span key={index}>{a.reference}</span>
                  ))}
                  )
                </Tag>
              </span>
            )
          }
          return source
        }
      },
      {
        key: 'actions',
        render: record => {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Dropdown
                overlay={
                  <Menu>
                    <Item
                      key='0'
                      onClick={() => {
                        setRecordingDrawer(true)
                        setRecording(record)
                      }}
                    >
                      <EditOutlined />
                      &nbsp;
                      <FormattedMessage id='edit' />
                    </Item>
                    <Item key='1' onClick={() => getPDF(record, 'recording')}>
                      <Icon type='file-pdf' />
                      &nbsp;
                      <FormattedMessage id='generate pdf' />
                    </Item>

                    <Divider />

                    {((record.submissions &&
                      !record.submissions.some(e => e.name === 'defect')) ||
                      !record.submissions) && (
                      <Item key='5' onClick={() => createSingleDefect(record)}>
                        <Icon type='plus' />
                        &nbsp;
                        <FormattedMessage id='create defect mainx' />
                      </Item>
                    )}

                    {record.submissions &&
                      record.submissions.some(e => e.name === 'defect') && (
                        <Item disabled key='5'>
                          <CheckOutlined />
                          &nbsp;
                          <FormattedMessage id='defect created' />
                        </Item>
                      )}
                  </Menu>
                }
                trigger={['click']}
              >
                <a className='ant-dropdown-link' href='#'>
                  <Icon
                    type='more'
                    style={{ fontSize: '2rem', color: '#444' }}
                  />
                </a>
              </Dropdown>
            </div>
          )
        }
      }
    ]

    return (
      <Table
        className='acceptance-ant-expanded'
        rowKey={record => record._id}
        columns={expandedRows}
        dataSource={acceptance.recordings}
        onRow={record => ({
          onDoubleClick: () => {
            setRecordingDrawer(true)
            setRecording(record)
          }
        })}
        pagination={false}
      />
    )
  }

  const dataSource = useMemo(() => data.docs || [], [data])

  return (
    <>
      <PageProvider
        isAcceptance
        record={recording}
        setRecord={setRecording}
        activeAcceptance={acceptance}
        setActiveAcceptance={setAcceptance}
        defectCreationData={defectCreationData}
        setDefectCreationData={setDefectCreationData}
      >
        <FormattedMessage id='head.title.acceptances'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({
            id: 'acceptances'
          })}
        />

        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          rowKey={record => record._id}
          onChange={onChange}
          onRow={record => ({
            onDoubleClick: () => handleOpen(record)
          })}
          defaultPageSize={pagination.defaultPageSize}
          pagination={pagination}
          expandable={{
            expandedRowRender,
            // eslint-disable-next-line no-unused-vars
            rowExpandable: record => record.recordings.length > 0
          }}
          expandedRowRender={expandedRowRender}
        />

        <AcceptanceManagement
          acceptance={acceptance}
          visible={acceptanceDrawerVisible}
          onSubmit={handleClose}
          onClose={handleClose}
        />

        <DefectCreationManagement
          defect={defectCreationData}
          visible={defectCreationVisible}
          onSubmit={handleClose}
          onClose={handleClose}
        />

        <RecordingManagement
          recording={recording}
          visible={recordingDrawer}
          onSubmit={handleClose}
          onClose={handleClose}
        />
      </PageProvider>
    </>
  )
}

export default AcceptancesPage
