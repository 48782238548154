import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import Popconfirm from '../../../components/Popconfirm'
import specialWorkService from '../../../services/specialWork'
import FormSpecialwork from './FormSpecialwork'

const SpecialWorkManagement = ({ visible, work, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createWork] = useApi(specialWorkService.add)
  const [updating, updateWork] = useApi(specialWorkService.update)
  const [deleting, deleteWork] = useApi(specialWorkService.delete)

  const handleSubmit = async () => {
    try {
      const { _id } = work || {}
      const values = await form.current.validateFields()
      onSubmit(await (_id ? updateWork(_id, values) : createWork(values)))
    } catch (error) {}
  }

  const handleDelete = async () => onSubmit(await deleteWork(work._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && work) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${work ? 'edit' : 'create'} special work`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormSpecialwork ref={form} work={work} />
    </Drawer>
  )
}

SpecialWorkManagement.propTypes = {
  work: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

SpecialWorkManagement.defaultProps = {
  visible: false
}

export default SpecialWorkManagement
