import React from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import 'react-rrule-generator/build/styles.css'
import RRuleGenerator from 'react-rrule-generator'
import translations from 'translations/rruleGenerator'

const { Item } = Form

const Recurring = () => {
  const lang = useSelector(
    ({ settings: { lang } }) => lang
  )

  return (
    <Item wrapperCol={24} name='rrule'>
      <RRuleGenerator
        config={{ hideStart: false }}
        translations={translations[lang]}
      />
    </Item>
  )
}

export default Recurring
