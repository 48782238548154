import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Button, Table } from 'antd'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { CheckOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import searchColumn from '../../../utils/searchColumn'
import WorkOrderManagement from './WorkOrderManagement'
import usePagination from '../../../hooks/usePagination'
import PageProvider from '../../../providers/PageProvider'
import PageTitle from '../../../components/Global/PageTitle'
import scheduledWorkOrders from '../../../services/scheduledWorkOrders'

const ScheduledWorkOrders = () => {
  const intl = useIntl()
  const inputName = useRef()
  const inputNumber = useRef()
  const inputDescription = useRef()
  const [workOrder, setWorkOrder] = useState()
  const [data, setData] = useState({})
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, fetchWorkOrders] = useApi(scheduledWorkOrders.listV2)

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchWorkOrders({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const pagination = {
    size: 'default',
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    total: data.totalDocs || 0,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const {
    filterOptions: {
      createdBy: createdByFilters = [],
      executedBy: executedByFilters = []
    } = {}
  } = data

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const handleOpen = record => {
    setWorkOrder(record)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setWorkOrder()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  const columns = [
    {
      width: 130,
      title: intl.formatMessage({ id: 'number' }),
      dataIndex: 'docNumber',
      ...searchColumn({ input: inputNumber, intl, value: filters.docNumber })
    },
    {
      width: 130,
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      sorter: true,
      ...searchColumn({ input: inputName, intl, value: filters.name })
    },
    {
      width: 190,
      title: intl.formatMessage({ id: 'description' }),
      dataIndex: 'description',
      ...searchColumn({ input: inputDescription, intl, value: filters.description })
    },
    {
      width: 75,
      title: intl.formatMessage({ id: 'active' }),
      dataIndex: 'active',
      align: 'center',
      render: active =>
        active && <CheckOutlined style={{ color: '#005591' }} />
    },
    {
      width: 100,
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      width: 100,
      title: intl.formatMessage({ id: 'assignee' }),
      dataIndex: 'executedBy',
      render: executedBy => {
        const { firstName = '', lastName = '' } = executedBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: executedByFilters.map(user => {
        const { firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: user._id
        }
      }),
      defaultSortOrder: sortField === 'executedBy' && sortOrder,
      defaultFilteredValue: filters.executedBy
    },
    {
      width: 100,
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: user._id
        }
      }),
      defaultSortOrder: sortField === 'createdBy' && sortOrder,
      defaultFilteredValue: filters.createdBy
    },
    {
      width: 100,
      title: intl.formatMessage({ id: 'starts at' }),
      dataIndex: 'startDate',
      render: startDate =>
        startDate
          ? moment(startDate).format('DD.MM.YYYY, HH:mm')
          : '',
      sorter: true,
      defaultSortOrder: sortField === 'startDate' && sortOrder
    },
    {
      width: 65,
      align: 'right',
      key: 'actions',
      render: record => (
        <Button onClick={() => handleOpen(record)}>
          {(record.isVerified || record.isInvalid)
            ? <InfoCircleOutlined />
            : <EditOutlined />
          }
        </Button>
      )
    }
  ]

  const dataSource = data.docs || []

  const headerButtons = [
    <Button
      type='primary'
      key='create-scheduled'
      onClick={() => handleOpen()}
    >
      <FormattedMessage id='create scheduled workorder' />
    </Button>
  ]

  return (
    <>
      <PageProvider data={data} setData={setData} setWorkOrder={setWorkOrder}>
        <FormattedMessage id='head.title.workorders'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({
            id: 'workorder planning'
          })}
          buttons={headerButtons}
        />

        <Table
          rowKey='_id'
          size='small'
          loading={loading}
          columns={columns}
          onChange={onChange}
          scroll={{ x: 1000 }}
          dataSource={dataSource}
          pagination={pagination}
          onRow={record => ({
            onDoubleClick: () => handleOpen(record)
          })}
        />

        <WorkOrderManagement
          scheduled
          visible={drawerVisible}
          workOrder={workOrder}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      </PageProvider>
    </>
  )
}

export default ScheduledWorkOrders
