import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form, Input, Select } from 'antd'
import { useSelector } from 'react-redux'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

const Specification = ({ object }) => {
  const { readOnly } = useSelector(
    ({ recordings: { pickedReadOnly } }) => pickedReadOnly
  )

  const {
    objectType: { fields }
  } = object

  return (
    <>
      {(fields || []).map(
        ({ _id, label, key, element, value, options, choices }) => {
          switch (element) {
            case 'TextArea':
              return (
                <React.Fragment key={_id}>
                  <Item name={['fields', _id, '_id']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'choices']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'key']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item
                    {...formItemLayout}
                    key={_id}
                    name={['fields', _id, 'value']}
                    label={label}
                  >
                    <TextArea disabled={readOnly} />
                  </Item>
                </React.Fragment>
              )
            case 'Checkboxes':
              return (
                <React.Fragment key={_id}>
                  <Item name={['fields', _id, '_id']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'choices']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'key']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item
                    {...formItemLayout}
                    key={_id}
                    name={['fields', _id, 'value']}
                    label={label}
                  >
                    {(options || []).map(option => (
                      <p key={option.key} style={{ margin: 0 }}>
                        <Checkbox
                          disabled={readOnly}
                          checked={(choices || []).includes(option.value)}
                        />
                        {option.text}
                      </p>
                    ))}
                  </Item>
                </React.Fragment>
              )
            case 'Dropdown':
              return (
                <React.Fragment key={_id}>
                  <Item name={['fields', _id, '_id']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'choices']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'key']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item
                    {...formItemLayout}
                    key={_id}
                    name={['fields', _id, 'value']}
                    label={label}
                  >
                    <Select
                      style={{ width: '100%' }}
                      disabled={readOnly}
                      allowClear
                    >
                      {(options || []).map(option => (
                        <Option key={option.key} value={option.value}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </React.Fragment>
              )
            default:
              return (
                <React.Fragment key={_id}>
                  <Item name={['fields', _id, '_id']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'choices']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item name={['fields', _id, 'key']} noStyle>
                    <Input hidden />
                  </Item>
                  <Item
                    {...formItemLayout}
                    name={['fields', _id, 'value']}
                    label={label}
                  >
                    <Input />
                  </Item>
                </React.Fragment>
              )
          }
        }
      )}
    </>
  )
}

Specification.propTypes = {
  object: PropTypes.object
}

export default Specification
