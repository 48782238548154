import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import { Collapse } from 'antd'
import Zoom from 'react-medium-image-zoom'

const { Panel } = Collapse

const RecordingPreview = ({ recording }) => {
  const { name, images = [] } = recording || {}

  return (
    <Collapse defaultActiveKey={['1']} className='recording-preview-wrap'>
      <Panel header={`${name}`} key='1'>
        <div className='image-block-cover'>
          {images.map((value, index) => (
            <div className='image-block' key={index}>
              <Zoom zoomMargin={40}>
                <img
                  src={config.server.url + value.imageThumbUrl}
                  className='img'
                  style={{ width: 100 }}
                  alt={name || ''}
                />
              </Zoom>
              <p>{value.comment}</p>
            </div>
          ))}
        </div>
      </Panel>
    </Collapse>
  )
}

RecordingPreview.propTypes = {
  recording: PropTypes.object
}

export default RecordingPreview
