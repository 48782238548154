import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { DatePicker, Form, Input, Select, Switch, Tag } from 'antd'

import useObjects from '../../../../hooks/useObjects'
import useProjects from '../../../../hooks/useProjects'
import useContractSections from '../../../../hooks/useContractSections'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const Details = ({ recording, disabled, defect }) => {
  const intl = useIntl()

  const {
    project,
    objectId,
    submitted,
    createdBy,
    submittedAt,
    acceptanceId,
    submittedRefNr,
    contractSection
  } = recording || {}

  const {
    loading: loadingProjects,
    items: projects,
    fetch: fetchProjects
  } = useProjects(project)

  const {
    loading: loadingObjects,
    items: objects,
    fetch: fetchObjects
  } = useObjects(objectId)

  const {
    loading: loadingContractSections,
    items: contractSections,
    fetch: fetchContractSections
  } = useContractSections(contractSection)

  const { scope: [scope], objectName } = useSelector(
    ({
      auth: {
        authUser: { scope }
      },
      settings: {
        data: { buildx: { objectName = 'object number' } = {} }
      }
    }) => ({ scope, objectName })
  )

  return (
    <>
      <h3>
        <FormattedMessage id='informations' />
      </h3>

      <Item name='_id' noStyle>
        <Input type='hidden' />
      </Item>

      <Item
        name='name'
        label={intl.formatMessage({ id: 'title' })}
      >
        <Input
          maxLength={40}
          disabled={disabled}
        />
      </Item>

      <Item
        name='project'
        label={intl.formatMessage({ id: 'project' })}
      >
        <Select
          showSearch
          allowClear
          disabled={disabled}
          loading={loadingProjects}
          style={{ width: '100%' }}
          optionFilterProp='children'
          onSearch={debounce(fetchProjects, 300)}
        >
          {projects.map(({ _id, projectName }) => (
            <Option key={_id} value={_id}>{projectName}</Option>
          ))}
        </Select>
      </Item>

      <Item
        name='date'
        label={intl.formatMessage({ id: 'date' })}
      >
        <DatePicker
          showTime
          allowClear={false}
          disabled={disabled}
          format='DD.MM.YYYY HH:mm'
        />
      </Item>

      <Item
        label={intl.formatMessage({ id: 'created by' })}
      >
        {!!createdBy && (
          <span className='ant-form-text'>
            {createdBy.fullName}
          </span>
        )}
      </Item>

      <Item name='objectId' label={objectName}>
        <Select
          showSearch
          allowClear
          disabled={disabled}
          filterOption={false}
          loading={loadingObjects}
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({
            id: `choose a ${objectName}`
          })}
          onSearch={debounce(fetchObjects, 300)}
        >
          {objects.map(({ _id, name }) => (
            <Option key={_id} value={_id}>{name}</Option>
          ))}
        </Select>
      </Item>

      <h3>
        <FormattedMessage id='general' />
      </h3>

      <Item
        name={['attributes', 'actualStatus']}
        label={intl.formatMessage({
          id: 'actual status'
        })}
      >
        <TextArea
          disabled={disabled}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Item>

      <Item
        name={['attributes', 'targetStatus']}
        label={intl.formatMessage({ id: 'target status' })}
      >
        <TextArea
          disabled={disabled}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Item>

      <Item
        name='comment'
        label={intl.formatMessage({ id: 'comment' })}
      >
        <TextArea
          disabled={disabled}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Item>

      {(!acceptanceId && ['admin', 'superadmin'].includes(scope)) && (
        <>
          <Item
            name='status'
            label={intl.formatMessage({ id: 'status' })}
          >
            <Select
              allowClear
              disabled={defect}
              style={{ width: '100%' }}
            >
              <Option value='opened'>
                <FormattedMessage id='opened' />
              </Option>
              <Option value='rejected'>
                <FormattedMessage id='rejected' />
              </Option>
              <Option value='approved'>
                <FormattedMessage id='approved' />
              </Option>
              <Option value='invalid'>
                <FormattedMessage id='invalid' />
              </Option>
            </Select>
          </Item>

          <Item
            name='archived'
            valuePropName='checked'
            label={intl.formatMessage({ id: 'archived' })}
          >
            <Switch
              disabled={disabled}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Item>

          {submitted && (
            <>
              <h3>
                <FormattedMessage id='upload' />
              </h3>

              <Item
                label={intl.formatMessage({ id: 'submitted at' })}
              >
                <span className='ant-form-text'>
                  {!!submittedAt && moment(submittedAt).format('DD.MM.YYYY, HH:mm')}
                </span>
              </Item>

              <Item
                label={intl.formatMessage({ id: 'reference number' })}
              >
                <span className='ant-form-text'>
                  {!!submittedRefNr && submittedRefNr}
                </span>
              </Item>

              <Item
                label={intl.formatMessage({ id: 'status' })}
              >
                <span className='ant-form-text'>
                  {!!submittedRefNr && (
                    <Tag color='#87d068'>
                      <CheckOutlined /> erfolgreich
                    </Tag>
                  )}
                </span>
              </Item>
            </>
          )}
        </>
      )}

      {acceptanceId && (
        <Item
          name='contractSection'
          label={intl.formatMessage({ id: 'contract section' })}
        >
          <Select
            showSearch
            allowClear
            disabled={disabled}
            filterOption={false}
            style={{ width: '100%' }}
            loading={loadingContractSections}
            onSearch={debounce(fetchContractSections, 300)}
          >
            {contractSections.map(({ _id, name }) => (
              <Option key={_id} value={_id}>{name}</Option>
            ))}
          </Select>
        </Item>
      )}
    </>
  )
}

Details.propTypes = {
  defect: PropTypes.bool,
  disabled: PropTypes.bool,
  recording: PropTypes.object
}

export default Details
