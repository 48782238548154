import React, { forwardRef, useMemo } from 'react'
import moment from 'moment'
import { Form, Tabs } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import Photos from './Tabs/Photos'
import Details from './Tabs/Details'
import CustomForm from './Tabs/CustomForm'
import Products from './Tabs/Products'

const { TabPane } = Tabs

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  label {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 1rem;
  }
`

const FormRecording = forwardRef(({ recording, acceptance, disabled }, ref) => {
  const intl = useIntl()

  const { projects, customForms, dataXEnabled } = useSelector(
    ({
      project: { projectList: projects },
      settings: {
        customForms,
        data: { datax: { enabled: dataXEnabled } = {} } = {}
      }
    }) => ({ projects, customForms, dataXEnabled })
  )

  const customForm = useMemo(() => {
    if (acceptance) {
      if (recording && recording.project) {
        const project = projects.find(
          ({ _id }) => _id === recording.project._id
        )
        return project && project.custom_forms
          ? project.custom_forms.acceptance
          : customForms.acceptance
      }

      return customForms.acceptance
    }

    return recording && recording.project && recording.project.form
      ? recording.project.form
      : customForms.recording
  }, [acceptance, recording, projects, customForms])

  const {
    _id,
    name,
    date,
    status,
    images,
    project,
    comment,
    objectId,
    archived,
    attributes,
    contractSection,
    products
  } = recording || {}

  const extraFields = useMemo(() => {
    const { fields } = customForm || {}
    return (fields || []).reduce((fields, { id }) => {
      const { extra_fields: extra } = attributes || []
      const { value, choices } =
        (extra || []).find(item => item.id === id) || {}
      fields[id] = {
        id,
        value: value || null,
        choices: choices || []
      }
      return fields
    }, {})
  }, [customForm, attributes])

  const values = {
    _id,
    name,
    status,
    comment,
    archived,
    date: moment(date),
    project: project && project._id,
    objectId: objectId && objectId._id,
    contractSection: contractSection && contractSection._id,
    images: (images || []).reduce(
      (images, { _id, comment }) =>
        Object.assign(images, { [_id]: { comment } }),
      {}
    ),
    attributes: { ...attributes, extra_fields: extraFields },
    products: products || []
  }
  return (
    <StyledForm ref={ref} {...formLayout} initialValues={values}>
      <Tabs defaultActiveKey='form'>
        <TabPane key='form' tab={intl.formatMessage({ id: 'informations' })}>
          <Details recording={recording} disabled={disabled} />
        </TabPane>
        <TabPane key='photos' tab={intl.formatMessage({ id: 'photos' })}>
          <Photos recording={recording} disabled={disabled} />
        </TabPane>
        {!!customForm && (customForm.fields || []).length > 0 && (
          <TabPane tab={customForm.name} key='extraFields' forceRender>
            <CustomForm form={customForm} disabled={disabled} />
          </TabPane>
        )}
        {!acceptance && dataXEnabled && (
          <TabPane
            forceRender
            key='products'
            tab={intl.formatMessage({ id: 'products' })}
          >
            <Products disabled={disabled} />
          </TabPane>
        )}
      </Tabs>
    </StyledForm>
  )
})

FormRecording.propTypes = {
  disabled: PropTypes.bool,
  acceptance: PropTypes.bool,
  recording: PropTypes.object.isRequired
}

export default FormRecording
