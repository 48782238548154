const isObject = value => value && typeof value === 'object' && value.constructor === Object

const convertToObjectId = value => {
  if (isObject(value)) {
    if (value.hasOwnProperty('_id')) return value._id
    return Object.entries(value).reduce((object, [key, value]) =>
      Object.assign(object, { [key]: convertToObjectId(value) }), {})
  }
  if (Array.isArray(value)) return value.map(convertToObjectId)
  return value
}

export default convertToObjectId
