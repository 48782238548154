import React, { useContext, useState, useMemo } from 'react'
import { Button, Form, Input, notification, Popconfirm, Table } from 'antd'
import { Icon } from '@ant-design/compatible'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import EditableCell from './EditableCell'
import { PageContext } from '../../../../../providers/PageProvider'

const { Item } = Form

const buttonSpan = {
  marginRight: 8,
  color: '#005591',
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer'
}

const components = {
  body: {
    cell: EditableCell
  }
}

const ProductRow = ({ products, category }) => {
  const intl = useIntl()
  const { record, setRecord } = useContext(PageContext)
  const readOnly = useSelector(state => state.recordings.pickedReadOnly)
  const [editValue, setEditValue] = useState('')
  const [pickedProduct, setPickedProduct] = useState({})
  const [editingItem, setEditingItem] = useState('')

  const isEditing = (entity, rowData) =>
    (entity.product && entity.product._id === editingItem) ||
    (editingItem === entity.id &&
      (rowData && rowData._id) === pickedProduct._id) ||
    (editingItem === entity.id &&
      (rowData && rowData.product && rowData.product._id) ===
        (pickedProduct.product && pickedProduct.product._id))

  const save = entity => {
    const recording = { ...record }
    try {
      const findProductIndex = recording.products.findIndex(
        item =>
          (entity.product && item.product._id === entity.product._id) ||
          (pickedProduct.product &&
            item.product._id === pickedProduct.product._id)
      )
      let itemUpdated = false

      if (!entity.product_id && entity.quantity) {
        if (Number(editValue) > 50 || Number(editValue) < 1) {
          notification.error({
            message: 'Only numbers 1 - 50'
          })
          return false
        }
        recording.products[findProductIndex].quantity = editValue
      }

      if (!recording.products[findProductIndex].extra_fields) {
        recording.products[findProductIndex].extra_fields = []
      }

      recording.products[findProductIndex].extra_fields.map(field => {
        if (field.id === entity.id) {
          field.value = editValue
          if (!entity.product_id) {
            itemUpdated = true
          }
        }
        return field
      })

      if (!entity.product_id && !itemUpdated && !entity.quantity) {
        recording.products[findProductIndex].extra_fields.push({
          ...entity,
          value: editValue
        })
      }

      setRecord(recording)
      clear()
    } catch (e) {
      console.error(e)
    }
  }

  const edit = (item, rowData) => {
    setEditingItem(item.product ? item.product._id : item.id)
    setPickedProduct(rowData && rowData.product ? rowData : item)
    setEditValue(item.product ? item.quantity : item.value)
  }

  const clear = () => {
    setEditValue('')
    setPickedProduct({})
    setEditingItem('')
  }

  const removeProductFromSection = product => {
    const updateRecord = { ...record }
    const findIndex = record.products.findIndex(
      p => p.product._id === product.product._id
    )

    updateRecord.products.splice(findIndex, 1)
    setRecord(updateRecord)
  }

  const expandedRowRender = rowData => {
    if (!category) return

    const columns = [
      {
        title: 'Field Name',
        dataIndex: 'label',
        key: 'id'
      },
      {
        title: 'Value',
        key: 'value',
        render: (value, entity) =>
          entity.element === 'Dropdown' && entity.options[entity.value]
            ? entity.options[entity.value].text
            : entity.value,
        onCell: entity => ({
          record: entity,
          inputType: entity.element,
          dataIndex: 'value',
          title: 'value',
          editValue,
          key: entity._id,
          onEditingValueChange: (value, entity) => {
            setEditingItem(entity.product ? entity.product._id : entity.id)
            setEditValue(value)
          },
          editing: isEditing(entity, rowData)
        })
      },
      {
        key: 'actions',
        render: (text, entity) =>
          isEditing(entity, rowData) ? (
            <span>
              <Button onClick={() => save(entity)}>
                <Icon type='save' />
              </Button>
              <Button onClick={() => clear(entity)}>
                {intl.formatMessage({ id: 'cancel' })}
              </Button>
            </span>
          ) : (
            <div style={{ width: 150 }}>
              <Button
                onClick={() => edit(entity, rowData)}
                disabled={editingItem !== ''}
              >
                <Icon type='edit' />
              </Button>
            </div>
          )
      }
    ]
    const fields = (category.custom_form || {}).fields || []
    const formedFields = [...(rowData.extra_fields || [])]

    for (let i = 0; i < fields.length; i += 1) {
      const fieldWithValue = formedFields.some(
        formedField => formedField.id === fields[i].id
      )
      if (!fieldWithValue) {
        formedFields.push(fields[i])
      }
    }

    return (
      <Table
        columns={columns}
        components={components}
        dataSource={formedFields}
        pagination={false}
        rowKey={entity => entity._id}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'name' }),
        dataIndex: 'product',
        key: 'name',
        render: product => product.name
      },
      {
        title: intl.formatMessage({ id: 'article number' }),
        dataIndex: 'product',
        key: 'ordernumber',
        render: product => product.ordernumber
      },
      {
        title: intl.formatMessage({ id: 'quantity' }),
        dataIndex: 'quantity',
        key: 'quantity',
        onCell: entity => ({
          record: entity,
          inputType: 'number',
          dataIndex: 'quantity',
          title: 'quantity',
          editValue,
          onEditingValueChange: (value, entity) => {
            setEditingItem(entity.product ? entity.product._id : entity.id)
            setEditValue(value)
          },
          editing: isEditing(entity)
        })
      },
      {
        key: 'actions',
        render: (_, entity) =>
          isEditing(entity) ? (
            <span>
              <span onClick={() => save(entity)} style={buttonSpan}>
                {intl.formatMessage({ id: 'save' })}
              </span>
              <span onClick={() => clear(entity)} style={buttonSpan}>
                {intl.formatMessage({ id: 'cancel' })}
              </span>
            </span>
          ) : (
            <div style={{ width: 150 }}>
              <Button
                onClick={() => edit(entity)}
                disabled={editingItem !== ''}
              >
                <Icon type='edit' />
              </Button>
              {readOnly ? (
                <Button disabled={readOnly}>
                  <FormattedMessage id='remove' />
                </Button>
              ) : (
                <Popconfirm
                  key='remove'
                  title={intl.formatMessage({
                    id: 'do you really want to remove this product?'
                  })}
                  onConfirm={() => removeProductFromSection(entity)}
                  okText={intl.formatMessage({ id: 'yes' })}
                  cancelText={intl.formatMessage({ id: 'no' })}
                >
                  <Button disabled={readOnly}>
                    <FormattedMessage id='remove' />
                  </Button>
                </Popconfirm>
              )}
            </div>
          )
      }
    ],
    [editValue, intl, pickedProduct, editingItem]
  )

  return (
    <>
      <Item name={['products']} noStyle>
        <Input type='hidden' />
      </Item>
      <Table
        components={components}
        columns={columns}
        dataSource={products}
        rowKey={entity => entity.product._id}
        pagination={false}
        expandable={{
          expandedRowRender
        }}
      />
    </>
  )
}

ProductRow.propTypes = {
  products: PropTypes.array,
  category: PropTypes.object
}

export default ProductRow
