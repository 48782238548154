import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import objectService from '../../../services/object'
import FormObject from './FormObject'
import imageService from '../../../services/image'

const ObjectManagement = ({ visible, object, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const [creating, createObject] = useApi(objectService.create)
  const [updating, updateObject] = useApi(objectService.update)

  const handleSubmit = async () => {
    try {
      const {
        _id,
        images,
        fields,
        ...values
      } = await form.current.validateFields()

      await Promise.all(
        Object.entries(images || {})
          .filter(([_id]) =>
            form.current.isFieldTouched(['images', _id, 'comment'])
          )
          .map(([_id, { comment }]) => {
            const formData = new FormData()
            formData.append('comment', comment)
            return imageService.update(_id, formData)
          })
      )

      values.specificationFields = []
      for (const key in fields) {
        if (fields && fields[key].value) {
          values.specificationFields.push({
            _id: key,
            ...fields[key]
          })
        }
      }

      onSubmit(await (_id ? updateObject(_id, values) : createObject(values)))
    } catch (error) {}
  }

  const loading = creating || updating

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  const { name } = object || {}
  return (
    <Drawer
      title={
        name ||
        intl.formatMessage({
          id: 'create object'
        })
      }
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormObject ref={form} object={object} />
    </Drawer>
  )
}

ObjectManagement.propTypes = {
  object: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ObjectManagement.defaultProps = {
  visible: false
}

export default ObjectManagement
