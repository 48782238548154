import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Button, Form, Input, List, Select, Spin, Typography } from 'antd'
import useApi from '../../../../hooks/useApi'
import productService from '../../../../services/products'
import categoriesService from '../../../../services/categories'
import ProductRow from './components/ProductRow'
import { PageContext } from '../../../../providers/PageProvider'

const { Item } = Form
const { Title } = Typography
const { Option } = Select

const Products = ({ disabled }) => {
  const intl = useIntl()
  const { record, setRecord } = useContext(PageContext)
  const [loadingProducts, fetchProducts] = useApi(productService.listV2)
  const [loadingCategories, fetchCategories] = useApi(categoriesService.getList)
  const [renderAddProduct, setRenderAddProduct] = useState(false)
  const [categories, setCategories] = useState([])
  const [productsList, setProductsList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [searchProduct, setSearchProduct] = useState('')

  const loadData = async () => {
    const categoriesResponse = await fetchCategories()
    setCategories(categoriesResponse)
  }

  useEffect(() => {
    if (selectedCategory) {
      const filters = { category: [selectedCategory] }
      if (searchProduct) {
        filters.name = [searchProduct]
      }
      ;(async () =>
        await fetchProducts({
          limit: 10,
          offset: 1,
          sortOrder: 'descend',
          sortField: 'createdAt',
          ...filters
        }).then(({ docs }) => setProductsList(docs)))()
    }
  }, [selectedCategory, searchProduct])

  const sections = useMemo(() => {
    const { products } = record || {}
    if (!products) {
      return
    }

    const obj = {}

    for (let i = 0; i < products.length; i += 1) {
      if (products[i].extra_fields) {
        for (let j = 0; j < products[i].extra_fields.length; j += 1) {
          categories.some(category => {
            const filteredVal =
              category.custom_form &&
              category.custom_form.fields &&
              category.custom_form.fields.find(
                field => field.id === products[i].extra_fields[j].id
              )

            if (filteredVal) {
              products[i].extra_fields[j].label = filteredVal.label
              products[i].extra_fields[j].element = filteredVal.element
              products[i].extra_fields[j].product_id = products[i]._id
              products[i].extra_fields[j].options = filteredVal.options
              return true
            }
          })
        }
      }
      obj[products[i].product.category._id] = [
        ...(obj[products[i].product.category._id] || []),
        products[i]
      ]
    }

    return Object.keys(obj).map(key => (
      <div key={obj[key][0].product._id}>
        <Title style={{ marginTop: '20px' }} level={4}>
          {obj[key][0].product.category.name}
        </Title>
        <ProductRow
          products={obj[key]}
          category={categories.find(item => item._id === key)}
        />
      </div>
    ))
  }, [record, categories])

  const addProductToSection = product => {
    const recordToUpdate = { ...record }

    if (
      recordToUpdate.products &&
      recordToUpdate.products.filter(value => value.product._id === product._id)
        .length > 0
    ) {
      setRenderAddProduct(!renderAddProduct)
      return false
    }

    const productPayload = {
      key: product._id,
      product: {
        _id: product._id,
        category: product.category,
        name: product.name,
        ordernumber: product.ordernumber
      },
      quantity: 1
    }

    if (recordToUpdate.products) {
      recordToUpdate.products.push(productPayload)
    } else {
      recordToUpdate.products = [productPayload]
    }

    setRecord(recordToUpdate)
    setRenderAddProduct(!renderAddProduct)
  }

  useEffect(() => {
    !loadingCategories && loadData()
  }, [])

  return (
    <>
      {renderAddProduct ? (
        <Spin size='large' spinning={loadingCategories}>
          <div className='recordings-add-product-wrap'>
            <Button
              onClick={() => {
                setRenderAddProduct(!renderAddProduct)
              }}
              type='primary'
              className='recordings-products-tab-back'
            >
              {intl.formatMessage({ id: 'back' })}
            </Button>
            <Item label={intl.formatMessage({ id: 'category' })}>
              <Select
                showSearch
                allowClear
                disabled={disabled}
                loading={loadingCategories}
                defaultValue={intl.formatMessage({
                  id: 'choose category'
                })}
                style={{ width: 250 }}
                onChange={value => setSelectedCategory(value)}
              >
                {categories.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item label={intl.formatMessage({ id: 'search' })}>
              <Input
                disabled={disabled}
                onChange={e => {
                  setSearchProduct(e.target.value)
                }}
              />
            </Item>
            <Spin size='large' spinning={loadingProducts}>
              <List
                className='recordings-products-list'
                header={<div>{intl.formatMessage({ id: 'products' })}</div>}
                bordered
                dataSource={productsList}
                renderItem={item => (
                  <List.Item
                    className='recordings-products-list-item'
                    onClick={() => addProductToSection(item)}
                  >
                    {item.name}
                  </List.Item>
                )}
              />
            </Spin>
          </div>
        </Spin>
      ) : (
        <div className='recording-form-tables-wrap'>
          <Button
            onClick={() => setRenderAddProduct(!renderAddProduct)}
            type='primary'
            disabled={disabled}
          >
            {intl.formatMessage({ id: 'add product' })}
          </Button>
          {sections}
        </div>
      )}
    </>
  )
}

Products.propTypes = {
  disabled: PropTypes.bool
}

export default Products
