import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import saveAs from 'file-saver'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import { Table, Button, Tag, Menu, Dropdown, notification } from 'antd'
import {
  MoreOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  EditOutlined,
  EyeOutlined,
  FilePdfOutlined,
  InboxOutlined,
  PlusOutlined,
  DownloadOutlined,
  FileImageOutlined
} from '@ant-design/icons'

import useApi from '../../../hooks/useApi'
import searchColumn from '../../../utils/searchColumn'
import RecordingManagement from './RecordingManagement'
import usePagination from '../../../hooks/usePagination'
import recordingService from '../../../services/recording'
import PageTitle from '../../../components/Global/PageTitle'
import SearchObjectNumber from '../../../components/SearchObjectNumber'
import ModalAttachPicturesToObject from './ModalAttachPicturesToObject'
import PageProvider from '../../../providers/PageProvider'

const { Item, Divider } = Menu

const RecordingsPage = () => {
  const intl = useIntl()
  const inputName = useRef()
  const inputObject = useRef()
  const inputDocNumber = useRef()
  const inputSubmittedRefNr = useRef()
  const [record, setRecord] = useState()
  const [data, setData] = useState({})

  const [loading, fetchRecords] = useApi(recordingService.listV2)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [modalAttachVisible, setModalAttachVisible] = useState(false)

  const { objectName, sapEnabled, dataXEnabled, pickedProject } = useSelector(
    ({
      project: { pickedProject = {} },
      settings: {
        data: {
          apps: { sap: sapEnabled } = {},
          datax: { enabled: dataXEnabled } = {},
          buildx: { objectName = 'object number' } = {}
        }
      }
    }) => ({ objectName, sapEnabled, dataXEnabled, pickedProject })
  )

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt',
    filters: {
      archived: ['showUnarchived']
    }
  })

  const loadData = async () => {
    const response = await fetchRecords({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = record => {
    setRecord(record)
    setDrawerVisible(true)
  }

  const handleModalAttach = record => {
    setRecord(record)
    setModalAttachVisible(true)
  }

  const handleClose = () => {
    setRecord()
    setDrawerVisible(false)
    setModalAttachVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  const updateRecord = record => {
    const docs = [...(dataSource || [])]
    docs.splice(
      docs.findIndex(({ _id }) => _id === record._id),
      1,
      record
    )
    setData({ ...data, docs })
  }

  const handleArchive = async ({ _id }, func) => {
    try {
      const record = await recordingService[func](_id)
      updateRecord(record)
      notification.success({
        message: intl.formatMessage({
          id: `${func}d successfully`
        })
      })
    } catch (e) {
      notification.error({
        message: intl.formatMessage({
          id: `${func}d error`
        }),
        description: e.error || e.message
      })
    }
  }

  const handleCreateDefect = () => {}

  const generatePDF = async ({ _id, docNumber }) =>
    saveAs(
      new Blob([await recordingService.downloadPDF(_id)], {
        type: 'application/pdf'
      }),
      `${docNumber}.pdf`
    )

  const exportCSV = async () => {
    const params = {
      offset,
      sortOrder,
      sortField,
      ...filters
    }

    saveAs(
      new Blob([await recordingService.exportCSV(params)], {
        type: 'text/csv'
      }),
      'recordingsData.csv'
    )
  }

  const exportProductsCSV = async () => {
    const params = {
      offset,
      sortOrder,
      sortField,
      ...filters
    }

    saveAs(
      new Blob([await recordingService.exportProductsCSV(params)], {
        type: 'text/csv'
      }),
      'products.csv'
    )
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const archivedFilters = [
    {
      value: 'showAll',
      text: intl.formatMessage({ id: 'show all' })
    },
    {
      value: 'showUnarchived',
      text: intl.formatMessage({ id: 'show unarchived' })
    },
    {
      value: 'showArchived',
      text: intl.formatMessage({ id: 'show archived' })
    }
  ]

  const { filterOptions: { createdBy: createdByFilters = [] } = {} } = data

  const columns = [
    {
      key: 'status',
      align: 'center',
      render: () => <CloudUploadOutlined />
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      defaultSortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'number' }),
      dataIndex: 'docNumber',
      ...searchColumn({ input: inputDocNumber, intl, value: filters.docNumber })
    },
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input: inputName, intl, value: filters.name })
    },
    {
      title: objectName,
      dataIndex: ['objectId', 'name'],
      key: 'objectId',
      sorter: true,
      defaultSortOrder: sortField === 'objectId' && sortOrder,
      ...searchColumn({
        input: inputObject,
        intl,
        value: filters.objectId,
        component: SearchObjectNumber
      })
    },
    {
      title: intl.formatMessage({ id: 'archived' }),
      dataIndex: 'archived',
      render: archived =>
        archived && <CheckOutlined style={{ color: '#005591' }} />,
      filters: archivedFilters,
      filterMultiple: false,
      align: 'center',
      defaultFilteredValue: filters.archived
    },
    {
      title: intl.formatMessage({ id: 'uploaded by' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => {
        const { firstName = '', lastName = '' } = createdBy || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: user._id
        }
      }),
      defaultFilteredValue: filters.createdBy,
      defaultSortOrder: sortField === 'createdBy' && sortOrder
    }
  ]

  if (sapEnabled) {
    columns.push({
      title: intl.formatMessage({ id: 'reference number' }),
      key: 'submittedRefNr',
      ...searchColumn({
        input: inputSubmittedRefNr,
        intl,
        value: filters.submittedRefNr
      }),
      render: record => {
        const {
          submitted,
          submissions,
          submittedTask,
          submittedRefNr,
          submittedTaskId,
          submittedWorkorder,
          submittedWorkorderId
        } = record

        return (
          <>
            {submittedWorkorder && submittedWorkorderId && (
              <span key='1'>
                <Tag color='blue'>
                  <CheckOutlined />
                  &nbsp;
                  <FormattedMessage id='workorder' />
                  &nbsp; ({submittedWorkorderId.docNumber})
                </Tag>
              </span>
            )}
            {submittedTask && submittedTaskId && (
              <span key='2'>
                <Tag color='blue'>
                  <CheckOutlined />
                  &nbsp;
                  <FormattedMessage id='task' />
                  &nbsp; ({submittedTaskId.docNumber})
                </Tag>
              </span>
            )}
            {submitted && (
              <span key='3'>
                <Tag color='blue'>
                  <CheckOutlined />
                  &nbsp; SAP ({submittedRefNr})
                </Tag>
              </span>
            )}
            {(submissions || []).some(({ name }) => name === 'defect') && (
              <span key='4'>
                <Tag color='blue'>
                  <CheckOutlined />
                  &nbsp;
                  <FormattedMessage id='defect' />
                  &nbsp; (
                  {(submissions || []).map(({ reference }, index) => (
                    <span key={index}>{reference}</span>
                  ))}
                  )
                </Tag>
              </span>
            )}
          </>
        )
      }
    })
  }

  if (!pickedProject._id) {
    columns.push({
      title: intl.formatMessage({ id: 'project' }),
      key: 'project',
      dataIndex: ['project', 'projectName']
    })
  }

  columns.push({
    width: 50,
    align: 'right',
    key: 'actions',
    render: record => {
      const {
        status,
        archived,
        submitted,
        submissions,
        submittedWorkorder
      } = record

      const objectId = record.objectId || {}
      const objectImages = (objectId.images || []).map(({ _id }) => _id)
      const recordImages = (record.images || [])
        .filter(({ deleted }) => !deleted)
        .map(({ _id }) => _id)
      const showAttachImages = objectId._id && recordImages.length > 0
      const disabledAttachImages =
        showAttachImages &&
        recordImages.every(image => objectImages.includes(image))

      return (
        <Dropdown
          overlay={
            <Menu>
              <Item key='0' onClick={() => handleOpen(record)}>
                {status === 'approved' ? (
                  <>
                    <EyeOutlined />
                    <FormattedMessage id='view' />
                  </>
                ) : (
                  <>
                    <EditOutlined />
                    <FormattedMessage id='edit' />
                  </>
                )}
              </Item>
              {showAttachImages && (
                <Item
                  key='1'
                  disabled={disabledAttachImages}
                  onClick={() => handleModalAttach(record)}
                >
                  <FileImageOutlined />
                  <FormattedMessage id='attach pictures to object' />
                </Item>
              )}
              <Item key='2' onClick={() => generatePDF(record)}>
                <FilePdfOutlined />
                <FormattedMessage id='generate pdf' />
              </Item>

              {!archived && (
                <Item key='3' onClick={() => handleArchive(record, 'archive')}>
                  <InboxOutlined />
                  <FormattedMessage id='archive' />
                </Item>
              )}
              {archived && (
                <Item
                  key='4'
                  onClick={() => handleArchive(record, 'unarchive')}
                >
                  <InboxOutlined />
                  <FormattedMessage id='unarchive' />
                </Item>
              )}
              <Divider />
              {sapEnabled && submitted && (
                <Item key='5' disabled={submitted}>
                  <CheckOutlined />
                  <FormattedMessage id='submitted to sap' />
                </Item>
              )}

              {!(submissions || []).some(({ name }) => name === 'defect') &&
                !archived && (
                  <Item key='6' onClick={() => handleCreateDefect(record)}>
                    <PlusOutlined />
                    <FormattedMessage id='create defect mainx' />
                  </Item>
                )}

              {(submissions || []).some(({ name }) => name === 'defect') && (
                <Item disabled key='7'>
                  <CheckOutlined />
                  <FormattedMessage id='defect created mainx' />
                </Item>
              )}
              {submittedWorkorder && (
                <Item disabled key='8'>
                  <CheckOutlined />
                  <FormattedMessage id='mainx workorder created' />
                </Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <MoreOutlined style={{ fontSize: '2rem', color: '#444' }} />
        </Dropdown>
      )
    }
  })

  const headerButtons = [
    <Button key='csv-export' onClick={exportCSV} icon={<DownloadOutlined />}>
      <FormattedMessage id='csv export' />
    </Button>
  ]

  dataXEnabled &&
    headerButtons.push(
      <Button
        key='csv-export-products'
        onClick={exportProductsCSV}
        icon={<DownloadOutlined />}
      >
        &npsp;
        <FormattedMessage id='csv product export' />
      </Button>
    )

  const dataSource = data.docs || []

  return (
    <div>
      <PageProvider record={record} setRecord={setRecord}>
        <FormattedMessage id='head.title.recordings'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'recordings' })}
          buttons={headerButtons}
        />

        <Table
          rowKey='_id'
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          onChange={onChange}
          scroll={{ x: 2000 }}
          onRow={record => ({
            onDoubleClick: () => handleOpen(record)
          })}
          pagination={pagination}
        />

        <RecordingManagement
          recording={record}
          visible={drawerVisible}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />

        <ModalAttachPicturesToObject
          recording={record}
          onClose={handleClose}
          onSubmit={updateRecord}
          visible={modalAttachVisible}
        />
      </PageProvider>
    </div>
  )
}

export default RecordingsPage
