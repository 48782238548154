import React from 'react'
import config from 'config'
import PropTypes from 'prop-types'
import { Input, Form } from 'antd'
import Zoom from 'react-medium-image-zoom'
import { FormattedMessage, useIntl } from 'react-intl'

const { Item } = Form
const { TextArea } = Input

const Photos = ({ recording, disabled }) => {
  const intl = useIntl()
  const { images } = recording || {}

  return (images || []).map(({ _id, imageUrl }) => (
    <div key={_id}>
      <h3>
        <FormattedMessage id='photo' />
      </h3>
      <Item
        label={intl.formatMessage({ id: 'photo' })}
      >
        <Zoom zoomMargin={40}>
          <img
            className='img'
            alt='recording photo'
            style={{ width: '100%' }}
            src={`${config.server.url}${imageUrl}`}
          />
        </Zoom>
      </Item>
      <Item
        name={['images', _id, 'comment']}
        label={intl.formatMessage({ id: 'photo comment' })}
      >
        <TextArea
          disabled={disabled}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Item>
    </div>
  ))
}

Photos.propTypes = {
  disabled: PropTypes.bool,
  recording: PropTypes.object
}

export default Photos
