import React from 'react'
import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'

const { Item } = Form
const { Option } = Select

const EditableCell = ({
  editing,
  record,
  dataIndex,
  title,
  editValue,
  onEditingValueChange,
  inputType,
  children,
  ...props
}) => (
  <td {...props}>
    {editing ? (
      <Item
        name={dataIndex}
        style={{
          margin: 0
        }}
      >
        {['TextInput', 'Dropdown'].indexOf(inputType) < 0 && (
          <InputNumber
            value={editValue}
            onChange={e => onEditingValueChange(e, record)}
          />
        )}
        {inputType === 'Dropdown' && (
          <Select
            style={{ width: 100 }}
            onChange={e => onEditingValueChange(e, record)}
            value={
              record.options[Number(editValue)] &&
              record.options[Number(editValue)].text
            }
          >
            {record.options.map(option => (
              <Option key={option._id} value={option.value}>
                {option.text}
              </Option>
            ))}
          </Select>
        )}
        {inputType === 'TextInput' && (
          <Input
            value={editValue}
            onChange={e => onEditingValueChange(e.target.value, record)}
          />
        )}
      </Item>
    ) : (
      children
    )}
  </td>
)

EditableCell.propTypes = {
  editing: PropTypes.bool,
  record: PropTypes.object,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  editValue: PropTypes.any,
  onEditingValueChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ])
}

export default EditableCell
