import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Form, Input, InputNumber, Select, Spin } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import useContractSections from '../../../hooks/useContractSections'
import debounce from 'lodash/debounce'
import useObjects from '../../../hooks/useObjects'
import ContactTreeSelect from '../../../components/ContactTreeSelect'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const formItemLayout = {
  className: 'form-item-cover'
}

const StyledForm = styled(Form)`
  label {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 1rem;
  }
`

const Loader = () => (
  <div className='objects-spinner-container'>
    <Spin size='small' />
  </div>
)

const groupData = [
  'Kabel',
  'Leittechnik',
  'Materialschaden / falsches Material',
  'falsche Montage',
  'Beschriftung',
  'Brandschottung',
  'Doppelboden',
  'Reinigung',
  'Dokumentation',
  'Schema',
  'Schäden',
  'No-Break',
  'Andere',
  'Zu klären / fertigstellen',
  'Altlasten',
  'Baufehler'
]

const SelectWrapper = styled('div')`
  display: flex;
  & > .ant-btn {
    margin-left: 10px;
  }
  & > .ant-select {
    flex: 1;
  }
`

const SelectWithButton = ({ children, onClick, ...props }) => (
  <SelectWrapper>
    <Select {...props}>{children}</Select>
    <Button type='primary' onClick={onClick}>
      <PlusOutlined />
    </Button>
  </SelectWrapper>
)

SelectWithButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired
}

const DefectForm = forwardRef(
  ({ data, updateStateDefect, handleObjectOpen }, ref) => {
    const intl = useIntl()

    const { pickedProject, projectList = [] } =
      useSelector(({ project }) => project) || {}

    const { settings } = useSelector(({ settings }) => ({ settings }))

    const {
      _id,
      name,
      group,
      assignedTo,
      attributes,
      actualSituation,
      protocolResponsible,
      project,
      objectId,
      // eslint-disable-next-line camelcase
      estimated_cost,
      contractSection,
      protocolReference,
      protocolExtResponsible,
      protocolRefExt
    } = data || {}

    const {
      loading: loadingContractSections,
      items: contractSections,
      fetch: fetchContractSections
    } = useContractSections(contractSection)

    const {
      loading: loadingObjects,
      fetch: fetchObjects,
      items: objects
    } = useObjects(objectId)

    const selectContract = value => {
      const contract = contractSections.find(item => item._id === value)
      updateStateDefect('contractSections', contract)
    }

    const selectObject = value => {
      const object = objects.find(item => item._id === value)
      updateStateDefect('contractSections', object)
    }

    const renderContracts = useMemo(
      () => (
        <Select
          showSearch
          allowClear
          style={{ width: '100%' }}
          optionFilterProp='children'
          loading={loadingContractSections}
          notFoundContent={loadingContractSections && <Loader />}
          onSearch={debounce(fetchContractSections, 300)}
          onSelect={value => selectContract(value)}
        >
          {contractSections.map(element => (
            <Option key={element._id} data={element} value={element._id}>
              {element.name}
            </Option>
          ))}
        </Select>
      ),
      [loadingContractSections, contractSections]
    )

    const renderObjectsList = useMemo(
      () => (
        <SelectWithButton
          showSearch
          style={{ width: '80%' }}
          optionFilterProp='children'
          notFoundContent={loadingObjects && <Loader />}
          onClick={handleObjectOpen}
          onSearch={debounce(fetchObjects, 300)}
          onSelect={selectObject}
          value={objectId ? objectId._id : ''}
        >
          {objects.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </SelectWithButton>
      ),
      [objectId, loadingObjects, objects]
    )

    const values = {
      _id,
      name,
      group,
      assignedTo,
      attributes,
      actualSituation,
      estimated_cost,
      project: (project && project._id) || pickedProject._id,
      contractSection: contractSection ? contractSection._id : '',
      protocolResponsible,
      protocolReference,
      protocolExtResponsible,
      protocolRefExt,
      objectId: objectId ? objectId._id : ''
    }

    return (
      <StyledForm ref={ref} {...formLayout} initialValues={values}>
        <Item name='_id' valuePropName='_id' value={_id || ''} noStyle>
          <Input hidden />
        </Item>
        <div className='common-form-wrapper'>
          <p style={{ width: '100%', marginBottom: 0, marginTop: 16 }}>
            <b>{intl.formatMessage({ id: 'common data' })}</b>
          </p>
          <div style={{ width: '100%' }}>
            <Item
              name='project'
              {...formItemLayout}
              label={intl.formatMessage({ id: 'project' })}
            >
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp='children'
              >
                {projectList.map(project => (
                  <Option key={project._id} value={project._id}>
                    {project.projectName}
                  </Option>
                ))}
              </Select>
            </Item>
          </div>
          <Item
            name='assignedTo'
            {...formItemLayout}
            label={intl.formatMessage({ id: 'assignedTo' })}
          >
            <ContactTreeSelect
              placeholder={intl.formatMessage({ id: 'select contact' })}
            />
          </Item>
          <Item
            name='contractSection'
            {...formItemLayout}
            label={intl.formatMessage({ id: 'contract section' })}
          >
            {renderContracts}
          </Item>
          <Item
            name='protocolResponsible'
            {...formItemLayout}
            label={intl.formatMessage({ id: 'protocolResponsible' })}
          >
            <ContactTreeSelect
              placeholder={intl.formatMessage({ id: 'select contact' })}
            />
          </Item>
          <Item
            name='protocolReference'
            {...formItemLayout}
            label={intl.formatMessage({ id: 'protocolReference' })}
          >
            <Input />
          </Item>
          <Item
            name='protocolExtResponsible'
            {...formItemLayout}
            label={intl.formatMessage({ id: 'protocolExtResponsible' })}
          >
            <ContactTreeSelect
              placeholder={intl.formatMessage({ id: 'select contact' })}
            />
          </Item>
          <Item
            name='protocolRefExt'
            {...formItemLayout}
            label={intl.formatMessage({ id: 'protocolRefExt' })}
          >
            <Input />
          </Item>
        </div>
        <div className='specific-fields-wrapper'>
          <p style={{ width: '100%', marginBottom: 0, marginTop: 16 }}>
            <b>{intl.formatMessage({ id: 'specific data' })}</b>
          </p>
          <Item
            {...formItemLayout}
            name='name'
            label={intl.formatMessage({ id: 'name' })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'this field is required'
                })
              }
            ]}
          >
            <Input />
          </Item>
          <Item
            {...formItemLayout}
            name='objectId'
            label={
              settings &&
              settings.data &&
              settings.data.buildx &&
              settings.data.buildx.objectName
            }
            valuePropName='objectId'
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'this field is required'
                })
              }
            ]}
          >
            {renderObjectsList}
          </Item>
          <Item
            {...formItemLayout}
            name='estimated_cost'
            label={intl.formatMessage({ id: 'estimated_cost' })}
          >
            <InputNumber
              // eslint-disable-next-line camelcase
              defaultValue={estimated_cost || ''}
              formatter={
                value => `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '') // eslint-disable-line no-useless-escape
              }
              style={{ width: '100%' }}
              parser={value => value.replace(' ', '').match(/[0-9 , \.]+/g)} // eslint-disable-line no-useless-escape
            />
          </Item>

          <Item
            {...formItemLayout}
            name='group'
            label={intl.formatMessage({ id: 'group' })}
          >
            <Select onSelect={group => updateStateDefect('group', group)}>
              {groupData.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Item>

          <Item
            {...formItemLayout}
            name='actualSituation'
            label={intl.formatMessage({ id: 'actualSituation' })}
          >
            <TextArea />
          </Item>
        </div>
      </StyledForm>
    )
  }
)

DefectForm.propTypes = {
  data: PropTypes.object,
  updateStateDefect: PropTypes.func.isRequired,
  handleObjectOpen: PropTypes.func.isRequired
}

export default DefectForm
