import React, { useContext, useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import FormRecording from './FormRecording'
import Drawer from '../../../components/Drawer'
import Popconfirm from '../../../components/Popconfirm'
import recordingService from '../../../services/recording'
import imageService from '../../../services/image'
import { PageContext } from '../../../providers/PageProvider'

const RecordingManagement = ({
  visible,
  recording,
  onSubmit,
  onClose,
  isDefect
}) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const { isAcceptance } = useContext(PageContext)
  const [saving, saveRecording] = useApi(recordingService.save)
  const [deleting, deleteRecording] = useApi(recordingService.delete)

  const handleSubmit = async () => {
    try {
      const {
        images,
        products,
        ...values
      } = await form.current.validateFields()
      const productsToUpdate = []

      await Promise.all(
        Object.entries(images || {})
          .filter(([_id]) =>
            form.current.isFieldTouched(['images', _id, 'comment'])
          )
          .map(([_id, { comment }]) => {
            const formData = new FormData()
            formData.append('comment', comment)
            return imageService.update(_id, formData)
          })
      )

      if (products) {
        for (let i = 0; i < products.length; i += 1) {
          const extraFields = Object.values(products[i].extra_fields || []).map(
            item => ({
              id: item.id,
              value: item.value,
              choices: []
            })
          )
          productsToUpdate.push({
            product: products[i].product._id,
            quantity: products[i].quantity,
            extra_fields: extraFields
          })
        }
      }
      values.products = productsToUpdate
      values.attributes.extra_fields = Object.values(
        values.attributes.extra_fields || {}
      )

      onSubmit(await saveRecording(values))
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async () =>
    onSubmit(await deleteRecording(recording._id))

  const loading = saving || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (['admin', 'superadmin'].includes(scope) && !isDefect) {
    drawerButtons.unshift(
      <Popconfirm key='delete' onConfirmAction={handleDelete} />
    )
  }

  const { status } = recording || {}
  const readOnly = ['approved', 'invalid'].includes(status)

  return (
    <Drawer
      title={intl.formatMessage({
        id: readOnly ? 'recording details' : 'edit recording'
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormRecording
        ref={form}
        disabled={readOnly}
        recording={recording}
        acceptance={isAcceptance || false}
      />
    </Drawer>
  )
}

RecordingManagement.propTypes = {
  recording: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isDefect: PropTypes.bool
}

RecordingManagement.defaultProps = {
  visible: false,
  isDefect: false
}

export default RecordingManagement
