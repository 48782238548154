import config from 'config'
import axios from 'helpers/axios'

const recordingService = {
  list: (params = {}) => axios.get('/recording', params),

  listV2: (params = {}) =>
    axios.get(config.server.host + '/1.1/recording', params),

  downloadPDF: id =>
    axios.get(
      `/recording/${id}/pdf`,
      {},
      {
        responseType: 'arraybuffer'
      }
    ),

  exportCSV: (params = {}) =>
    axios.get('/recording-csv', params, {
      responseType: 'arraybuffer'
    }),

  exportProductsCSV: (params = {}) =>
    axios.get('/recording-products-csv', params, {
      responseType: 'arraybuffer'
    }),

  archive: id => axios.put(`/recording/${id}`, { archived: true }),

  unarchive: id => axios.put(`/recording/${id}`, { archived: false }),

  submit: id => axios.post(`/recording/${id}/submit`),

  async createWorkorder (recordingID) {
    return axios.post(`/recording/${recordingID}/create-workorder`)
  },

  async createTask (recordingID) {
    return axios.post(`/recording/${recordingID}/create-task`)
  },

  async createDefect (recordingID, params) {
    return axios.post(`/recording/${recordingID}/create-defect`, params)
  },

  save: ({ _id, ...payload }) => axios.put(`/recording/${_id}`, payload),

  delete: id => axios.delete(`/recording/${id}`),

  attachImages: (id, payload) =>
    axios.post(`/recording/${id}/attach-images`, payload)
}

export default recordingService
