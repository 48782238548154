import React, { useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { TreeSelect } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import useObjects from '../hooks/useObjects'

const { SHOW_CHILD } = TreeSelect

const ObjectTreeSelect = ({ value, objects, defaultValue, onChange, ...props }) => {
  const intl = useIntl()
  const firstLoad = useRef(false)
  const [state, setState] = useState(defaultValue || [])
  const [selected, setSelected] = useState(objects || [])

  const { loading, fetch, items } = useObjects(objects, { fetchOnMount: false })

  const treeData = useMemo(() => {
    const data = []
    const unAssignedList = []

    items.concat(selected).forEach(({ _id, name, objectType }) => {
      if (objectType && objectType._id) {
        if (!data.find(({ value }) => value === objectType._id)) {
          data.push({
            children: [],
            key: objectType._id,
            value: objectType._id,
            title: objectType.name
          })
        }
        const group = data.find(({ value }) => value === objectType._id)
        if (!group.children.find(({ value }) => value === _id)) {
          group.children.push({
            key: _id,
            value: _id,
            title: name
          })
        }
      } else if (!unAssignedList.find(({ value }) => value === _id)) {
        unAssignedList.push({
          key: _id,
          value: _id,
          title: name
        })
      }
    })

    data.push({
      title: `${intl.formatMessage({ id: 'no object type' })} (${
        unAssignedList.length
      })`,
      key: 'unassigned',
      value: 'unassigned',
      children: unAssignedList
    })

    return data
  }, [selected, items])

  const handleChange = value => {
    setSelected(items.filter(({ _id }) => (value || []).includes(_id)))
    const handle = typeof onChange === 'function' ? onChange : setState
    handle(value)
  }

  const handleFocus = () => {
    if (firstLoad.current) return

    firstLoad.current = true
    return fetch('')
  }

  return (
    <TreeSelect
      {...props}
      allowClear
      showSearch
      treeCheckable
      loading={loading}
      treeData={treeData}
      onFocus={handleFocus}
      value={value || state}
      filterTreeNode={false}
      onChange={handleChange}
      style={{ width: '100%' }}
      showCheckedStrategy={SHOW_CHILD}
      dropdownMatchSelectWidth={false}
      onSearch={debounce(fetch, 300)}
    />
  )
}

ObjectTreeSelect.propTypes = {
  value: PropTypes.array,
  objects: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string
}

export default ObjectTreeSelect
