import React, { useContext } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Avatar, Comment } from 'antd'
import { FormattedMessage } from 'react-intl'
import { UserOutlined } from '@ant-design/icons'

import Comments from '../../../../components/Comments'
import commentService from '../../../../services/comment'
import { PageContext } from '../../../../providers/PageProvider'
import scheduledWorkOrdersService from '../../../../services/scheduledWorkOrders'

const UpdatesComponent = styled('div')`
  padding-bottom: 30px;
`

const Updates = ({ workOrder }) => {
  const { _id, comments, createdBy, createdAt } = workOrder
  const { data, setData, setWorkOrder } = useContext(PageContext)

  const handleCreate = comment =>
    scheduledWorkOrdersService.addComment(_id, comment)

  const handleUpdate = ({ _id, ...comment }) =>
    commentService.update(_id, comment)

  const handleDelete = ({ _id }) =>
    commentService.delete(_id)

  const handleChange = comments => {
    const item = { ...workOrder, comments }
    const docs = Array.from((data || {}).docs || [])
    docs.splice(
      docs.findIndex(({ _id }) => _id === item._id),
      1,
      item
    )

    setData({ ...data, docs })
    setWorkOrder(item)
  }

  const { fullName = '' } = createdBy || {}

  return (
    <UpdatesComponent>
      <Comments
        value={comments}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onRemove={handleDelete}
        onChange={handleChange}
      />

      <Comment
        author={fullName}
        avatar={<Avatar icon={<UserOutlined />} />}
        content={<FormattedMessage id='scheduled workorder created' />}
        datetime={moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
      />
    </UpdatesComponent>
  )
}

Updates.propTypes = {
  workOrder: PropTypes.object.isRequired
}

export default Updates
