import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import saveAs from 'file-saver'
import useApi from '../../../hooks/useApi'
import objectService from '../../../services/object'
import usePagination from '../../../hooks/usePagination'
import { Helmet } from 'react-helmet'
import PageTitle from '../../../components/Global/PageTitle'
import { Button, Dropdown, Menu, notification, Table } from 'antd'
import { DownloadOutlined, ImportOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'
import RecordingStatusIcon from '../../../components/Global/RecordingStatusIcon'
import { Icon } from '@ant-design/compatible'
import searchColumn from '../../../utils/searchColumn'
import ImportModal from './ImportModal'
import ObjectManagement from './ObjectManagment'
import PageProvider from '../../../providers/PageProvider'
const { Item } = Menu

const ObjectsPage = () => {
  const intl = useIntl()
  const inputName = useRef()
  const [object, setObject] = useState()
  const [data, setData] = useState({})
  const [loader, setLoader] = useState(false)
  const [loading, fetchObjects] = useApi(objectService.listV2)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [importVisible, setImportVisible] = useState(false)

  const { selectedProject, settings } = useSelector(
    ({ project: { pickedProject = {} }, settings }) => ({
      settings,
      pickedProject
    })
  )

  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchObjects({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const exportCSV = async () => {
    const params = {
      offset,
      sortOrder,
      sortField,
      ...filters
    }

    saveAs(
      new Blob([await objectService.exportCSV(params)], {
        type: 'text/csv'
      }),
      'objectsData.csv'
    )
  }

  const handleOpen = record => {
    setObject(record)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setObject()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  const handleImportClose = (refresh = false) => {
    setImportVisible(false)
    return refresh && loadData()
  }

  const showPDF = async ({ _id, name }) =>
    saveAs(
      new Blob([await objectService.downloadPDF(_id)], {
        type: 'application/pdf'
      }),
      `${name}.pdf`
    )

  const toggleVerification = record => {
    setLoader(true)
    objectService
      .save({
        ...record,
        isVerified: !record.isVerified
      })
      .then(() => {
        setLoader(false)
        return loadData()
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({
            id: 'uploading error'
          })
        })
      })
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    size: 'default',
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    total: data.totalDocs || 0,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const {
    filterOptions: {
      createdBy: createdByFilters = [],
      objectTypes: objTypes = []
    } = {}
  } = data

  const dataSource = data.docs || []

  const columns = useMemo(() => {
    const cols = [
      {
        title: intl.formatMessage({ id: 'status' }),
        key: 'status',
        width: 15,
        align: 'center',
        render: (text, record) => {
          const checkedDate = record.lastChecked || ''
          let status = ''
          if (checkedDate && moment().diff(checkedDate, 'months') < 12) {
            status = 'approved'
          }

          return <RecordingStatusIcon status={status} />
        }
      },
      {
        title:
          (settings &&
            settings.data &&
            settings.data.buildx &&
            settings.data.buildx.objectName) ||
          'Name',
        key: 'name',
        width: 55,
        dataIndex: 'name',
        defaultSortOrder: (sortField === 'name' && sortOrder) || 'ascend',
        sorter: true,
        ...searchColumn({ input: inputName, intl, value: filters.name })
      },
      {
        title: intl.formatMessage({ id: 'last checked' }),
        key: 'lastChecked',
        width: 20,
        dataIndex: 'lastChecked',
        render: lastChecked =>
          lastChecked && moment(lastChecked).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder: (sortField === 'lastChecked' && sortOrder) || 'ascend'
      },
      {
        title: intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        width: 20,
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder: sortField === 'createdAt' && sortOrder
      },
      {
        title: intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        width: 20,
        dataIndex: 'createdBy',
        render: createdBy => {
          const { firstName = '', lastName = '' } = createdBy || {}
          return `${firstName} ${lastName}`
        },
        filters:
          createdByFilters.length > 0 &&
          createdByFilters.map(user => {
            const { _id, firstName = '', lastName = '' } = user
            return {
              text: `${firstName} ${lastName}`,
              value: _id
            }
          }),
        defaultFilteredValue: filters.createdBy,
        defaultSortOrder: sortField === 'createdBy' && sortOrder
      },
      {
        title: intl.formatMessage({ id: 'object type' }),
        key: 'objectType',
        width: 55,
        dataIndex: 'objectType',
        render: objectType => (objectType ? objectType.name : ''),
        filters: objTypes.map(objectType => ({
          text: objectType.name,
          value: objectType._id
        })),
        filteredValue: filters.objectType
      },
      {
        title: intl.formatMessage({ id: 'verified' }),
        key: 'isVerified',
        dataIndex: 'isVerified',
        width: 15,
        align: 'center',
        render: disabled => (
          <>{disabled && <Icon type='check' style={{ color: '#005591' }} />}</>
        ),
        filters: [
          {
            text: intl.formatMessage({ id: 'verified' }),
            value: true
          },
          {
            text: intl.formatMessage({ id: 'unverified' }),
            value: false
          }
        ],
        filteredValue: filters.verified
      }
    ]

    if (!selectedProject || !selectedProject._id) {
      cols.push({
        title: intl.formatMessage({ id: 'project' }),
        key: 'project',
        width: 55,
        dataIndex: 'project.projectName'
      })
    }

    cols.push({
      key: 'actions',
      width: 8,
      fixed: 'right',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Item key='0' onClick={() => handleOpen(record)}>
                  <Icon type={text.status === 'approved' ? 'eye' : 'edit'} />
                  {intl.formatMessage({
                    id: text.status === 'approved' ? 'view' : 'edit'
                  })}
                </Item>
                <Item key='1' onClick={() => showPDF(record)}>
                  <Icon type='file-pdf' />
                  <FormattedMessage id='generate pdf' />
                </Item>
                <Item key='1' onClick={() => toggleVerification(record)}>
                  {record.isVerified ? (
                    <>
                      <Icon type='close' />
                      <FormattedMessage id='reverse verification' />
                    </>
                  ) : (
                    <>
                      <Icon type='check' />
                      <FormattedMessage id='verify' />
                    </>
                  )}
                </Item>
              </Menu>
            }
            trigger={['click']}
          >
            <a className='ant-dropdown-link' href='#'>
              <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown>
        </div>
      )
    })

    return cols
  }, [
    selectedProject,
    filters,
    sortField,
    sortOrder,
    objTypes,
    createdByFilters,
    settings
  ])

  const headerButtons = [
    <Button key='csv-export' onClick={exportCSV} icon={<DownloadOutlined />}>
      <FormattedMessage id='csv export' />
    </Button>,
    <Button
      key='csv-import'
      disabled={loading}
      type='primary'
      style={{ marginLeft: 8 }}
      onClick={() => setImportVisible(true)}
    >
      <ImportOutlined />
      <FormattedMessage id='csv import' />
    </Button>,
    <Button
      key='create-object'
      type='primary'
      style={{ marginLeft: 16 }}
      onClick={() => handleOpen({})}
    >
      <FormattedMessage id='create object' />
    </Button>
  ]

  return (
    <>
      <PageProvider object={object} setObject={setObject}>
        <FormattedMessage id='head.title.objects'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'objects' })}
          buttons={headerButtons}
        />

        <Table
          rowKey='_id'
          size='small'
          loading={loading || loader}
          columns={columns}
          dataSource={dataSource}
          onChange={onChange}
          scroll={{ x: 1800 }}
          onRow={record => ({
            onDoubleClick: () => handleOpen(record)
          })}
          pagination={pagination}
        />

        <ObjectManagement
          object={object}
          visible={drawerVisible}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />

        <ImportModal visible={importVisible} onClose={handleImportClose} />
      </PageProvider>
    </>
  )
}

export default ObjectsPage
