import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input, Form, Checkbox, Select } from 'antd'

const { Item } = Form
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

const Styled = styled('div')`
  .ant-checkbox-group label {
    font-weight: normal;
  }
`

const CustomForm = ({ form, disabled }) => {
  const { name, fields } = form || {}
  return (
    <Styled>
      <h3>{name}</h3>
      {fields.map(({ id, label, options, element }) => {
        switch (element) {
          case 'TextArea':
            return (
              <React.Fragment key={id}>
                <Item
                  label={label}
                  {...formItemLayout}
                  name={['attributes', 'extra_fields', id, 'value']}
                >
                  <TextArea disabled={disabled} />
                </Item>
                <Item name={['attributes', 'extra_fields', id, 'id']} noStyle>
                  <Input type='hidden' />
                </Item>
                <Item
                  name={['attributes', 'extra_fields', id, 'choices']}
                  noStyle
                >
                  <Input type='hidden' />
                </Item>
              </React.Fragment>
            )
          case 'Checkboxes':
            return (
              <React.Fragment key={id}>
                <Item
                  label={label}
                  {...formItemLayout}
                  name={['attributes', 'extra_fields', id, 'choices']}
                >
                  <Checkbox.Group
                    options={(options || []).map(({ text }) => ({
                      disabled,
                      value: text,
                      label: text
                    }))}
                  />
                </Item>
                <Item name={['attributes', 'extra_fields', id, 'id']} noStyle>
                  <Input type='hidden' />
                </Item>
                <Item
                  name={['attributes', 'extra_fields', id, 'value']}
                  noStyle
                >
                  <Input type='hidden' />
                </Item>
              </React.Fragment>
            )
          case 'Dropdown':
            return (
              <React.Fragment key={id}>
                <Item
                  label={label}
                  {...formItemLayout}
                  name={['attributes', 'extra_fields', id, 'value']}
                >
                  <Select
                    allowClear
                    disabled={disabled}
                    style={{ width: '100%' }}
                    options={(options || []).map(({ text: label, value }) => ({
                      label,
                      value
                    }))}
                  />
                </Item>
                <Item name={['attributes', 'extra_fields', id, 'id']} noStyle>
                  <Input type='hidden' />
                </Item>
                <Item
                  name={['attributes', 'extra_fields', id, 'choices']}
                  noStyle
                >
                  <Input type='hidden' />
                </Item>
              </React.Fragment>
            )
          default:
            return (
              <React.Fragment key={id}>
                <Item
                  label={label}
                  {...formItemLayout}
                  name={['attributes', 'extra_fields', id, 'value']}
                >
                  <Input disabled={disabled} />
                </Item>
                <Item name={['attributes', 'extra_fields', id, 'id']} noStyle>
                  <Input type='hidden' />
                </Item>
                <Item
                  name={['attributes', 'extra_fields', id, 'choices']}
                  noStyle
                >
                  <Input type='hidden' />
                </Item>
              </React.Fragment>
            )
        }
      })}
    </Styled>
  )
}

CustomForm.propTypes = {
  form: PropTypes.object,
  disabled: PropTypes.bool,
  recording: PropTypes.object
}

export default CustomForm
