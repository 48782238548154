import React, { useRef, useState } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { Form, Table, Input, Button, Dropdown, Menu, Select } from 'antd'

import useChecklists from '../../../../hooks/useChecklists'
import ObjectTreeSelect from '../../../../components/ObjectTreeSelect'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const Styled = styled('div')`
  .actions {
    margin-top: 10px;
    text-align: right;
  }
  .custom-task-row .ant-table-cell {
    padding-bottom: 0;
    border-bottom: none;
  }
  .ant-table-cell {
    vertical-align: top;
  }
`

const uniqueId = () => Math.random().toString(36).substr(2, 9)

const TaskChecklist = ({ checklist, ...props }) => {
  const intl = useIntl()
  const firstLoad = useRef(false)

  const {
    loading,
    fetch,
    items
  } = useChecklists(checklist, {
    fetchOnMount: false
  })

  const handleFocus = () => {
    if (firstLoad.current) return

    firstLoad.current = true
    return fetch('')
  }

  return (
    <Select
      {...props}
      showSearch
      allowClear
      loading={loading}
      filterOption={false}
      style={{ width: '100%' }}
      placeholder={intl.formatMessage({
        id: 'choose a checklist'
      })}
      onFocus={handleFocus}
      onSearch={debounce(fetch, 300)}
    >
      {items.map(({ _id, name }) => (
        <Option key={_id}>{name}</Option>
      ))}
    </Select>
  )
}

TaskChecklist.propTypes = {
  checklist: PropTypes.object
}

const TaskRow = ({ children, ...props }) => {
  const intl = useIntl()
  const copyProps = {...props}
  const key = props['data-row-key']
  props.className += ' custom-task-row'
  copyProps['data-row-key'] += '-description'
  return (
    <>
      <tr {...props}>{children}</tr>
      {!props.className.includes('placeholder') && (
        <tr {...copyProps}>
          <td colSpan={children.length} className='ant-table-cell'>
            <Item name={['tasks', key, 'description']} noStyle>
              <TextArea
                autoSize={{ minRows: 1, maxRows: 4 }}
                placeholder={intl.formatMessage({ id: 'description' })}
              />
            </Item>
          </td>
        </tr>
      )}
    </>
  )
}

TaskRow.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  'data-row-key': PropTypes.string
}

const Tasks = ({ items }) => {
  const intl = useIntl()
  const [tasks, setTasks] = useState(items.map(({ _id, ...task }) => ({
    ...task,
    key: _id
  })))

  const addTask = () => setTasks([...tasks, {
    key: uniqueId()
  }])

  const removeTask = ({ key }) => setTasks(
    tasks.filter(task => task.key !== key)
  )

  const columns = [
    {
      key: 'name',
      title: intl.formatMessage({ id: 'task name' }),
      render: record => {
        const { key } = record || {}
        return (
          <Item
            wrapperCol={24}
            name={['tasks', key, 'name']}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'this field is required' })
              }
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'name' })} />
          </Item>
        )
      }
    },
    {
      key: 'objects',
      title: intl.formatMessage({ id: 'objects' }),
      render: record => {
        const { key, objects } = record || {}

        return (
          <Item
            wrapperCol={24}
            name={['tasks', key, 'objects']}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'this field is required' })
              }
            ]}
          >
            <ObjectTreeSelect objects={objects} />
          </Item>
        )
      }
    },
    {
      key: 'checklist',
      title: intl.formatMessage({ id: 'checklist' }),
      render: record => {
        const { key, checklist } = record || {}

        return (
          <Item wrapperCol={24} name={['tasks', key, 'checklist']}>
            <TaskChecklist checklist={checklist} />
          </Item>
        )
      }
    },
    {
      width: 50,
      align: 'right',
      key: 'actions',
      render: record => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='0' onClick={() => removeTask(record)}>
                  <DeleteOutlined />
                  <FormattedMessage id='delete task' />
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <MoreOutlined style={{ fontSize: '2rem', color: '#444' }} />
          </Dropdown>
        )
      }
    }
  ]

  return (
    <Styled>
      <Table
        columns={columns}
        dataSource={tasks}
        pagination={false}
        tableLayout='fixed'
        components={{
          body: {
            row: TaskRow
          }
        }}
      />
      <div className='actions'>
        <Button type='primary' onClick={addTask}>
          <FormattedMessage id='add task' />
        </Button>
      </div>
    </Styled>
  )
}

Tasks.propTypes = {
  items: PropTypes.array
}

export default Tasks
